export enum AirlineAlliance {
  STAR_ALLIANCE = 'Star Alliance',
  ONEWORLD = 'Oneworld',
  SKYTEAM = 'SkyTeam',
  NONE = 'None'
}

export enum AircraftStatus {
  ACTIVE = 'Active',
  MAINTENANCE = 'Maintenance',
  STORED = 'Stored',
  ON_ORDER = 'On Order'
}

export enum FlightStatus {
  SCHEDULED = 'Scheduled',
  BOARDING = 'Boarding',
  DEPARTED = 'Departed',
  EN_ROUTE = 'En Route',
  APPROACHING = 'Approaching',
  LANDED = 'Landed',
  DELAYED = 'Delayed',
  CANCELLED = 'Cancelled'
}

export enum UserRole {
  USER = 'user',
  ADMIN = 'admin',
  DEV = 'dev',
  MODERATOR = 'moderator'
}

export enum SubscriptionPlan {
  FREE = 'free',
  PROFESSIONAL = 'pro'
}