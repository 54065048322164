import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Check, AlertCircle, Loader2 } from 'lucide-react';
import { authService } from '@/services/auth.service';
import { useNavigate } from 'react-router-dom';

const UsernamePickerPage = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [isChecking, setIsChecking] = useState(false);
    const [isAvailable, setIsAvailable] = useState<boolean | null>(null);
    const [error, setError] = useState<string | null>(null);

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value);
        setIsChecking(true);
        setIsAvailable(null);

        if (e.target.value.length < 3) {
            setIsChecking(false);
            setIsAvailable(false);
            return;
        }

        Promise.resolve(authService.validateUsername(e.target.value)).then((isValid) => {
            if (!isValid) {
            setIsChecking(false);
            setIsAvailable(false);
            setError('Invalid username. Please try another.');
            return;
            }

            console.log('Checking username availability...');
            console.log('Username:', e.target.value);
            authService.isUsernameTaken(e.target.value).then((isTaken) => {
            setIsChecking(false);
            setIsAvailable(!isTaken);
            setError(isTaken ? 'Username is already taken' : null);
            });
        }).catch((err) => {
            setIsChecking(false);
            setIsAvailable(false);
            setError('Error validating username. Please try again.');
            console.error('Error validating username:', err);
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!username || !isAvailable) return;

        try {
            const storedUser = localStorage.getItem('user');
            if (!storedUser) {
              navigate('/auth');
              return;
            }
            const user = JSON.parse(storedUser);
            await authService.updateUserData(user.uid, { username });
            navigate('/game');
        } catch (error) {
            console.error('Error updating username:', error);
            setError('Failed to update username. Please try again.');
        }
    };

    return (
        <div className="min-h-screen bg-[#0A0A1E] flex items-center justify-center p-4">
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="w-full max-w-md"
            >
                <div className="bg-white/5 backdrop-blur-xl border border-white/10 rounded-2xl p-8">
                    {/* Header */}
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        className="text-center mb-8"
                    >
                        <h1 className="text-3xl font-bold mb-2">
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
                                Pick a username
                            </span>
                        </h1>
                        <p className="text-gray-400">
                            Choose something unique to identify yourself
                        </p>
                    </motion.div>

                    {/* Username Form */}
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="relative">
                            <motion.div
                                initial={false}
                                animate={{
                                    borderColor: error
                                        ? 'rgb(239, 68, 68)'
                                        : isAvailable
                                            ? 'rgb(34, 197, 94)'
                                            : 'rgba(255, 255, 255, 0.1)'
                                }}
                                className="relative flex items-center"
                            >
                                <span className="absolute left-4 text-gray-400">@</span>
                                <input
                                    type="text"
                                    value={username}
                                    onChange={handleUsernameChange}
                                    className="w-full pl-8 pr-12 py-3 bg-white/5 border border-white/10 rounded-xl 
                    text-white placeholder:text-gray-400 focus:outline-none focus:border-purple-500 
                    transition-all"
                                    placeholder="username"
                                    autoFocus
                                />
                                <div className="absolute right-4">
                                    {isChecking ? (
                                        <Loader2 className="w-5 h-5 text-gray-400 animate-spin" />
                                    ) : username && !error ? (
                                        isAvailable ? (
                                            <Check className="w-5 h-5 text-green-500" />
                                        ) : (
                                            <AlertCircle className="w-5 h-5 text-red-500" />
                                        )
                                    ) : null}
                                </div>
                            </motion.div>

                            {/* Error or Status Message */}
                            <AnimatedMessage
                                error={error}
                                isAvailable={isAvailable}
                                username={username}
                            />
                        </div>

                        {/* Submit Button */}
                        <motion.button
                            type="submit"
                            disabled={!isAvailable || !!error || !username}
                            className="w-full py-3 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 
                text-white font-semibold relative overflow-hidden group 
                disabled:opacity-50 disabled:cursor-not-allowed"
                            whileHover={{ scale: 1.02 }}
                            whileTap={{ scale: 0.98 }}
                        >
                            <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full 
                group-hover:translate-x-full transition-transform duration-1000" />
                            <span className="relative">Continue</span>
                        </motion.button>
                    </form>
                </div>
            </motion.div>
        </div>
    );
};

const AnimatedMessage = ({
    error,
    isAvailable,
    username
}: {
    error: string | null;
    isAvailable: boolean | null;
    username: string;
}) => {
    return (
        <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            className="mt-2 min-h-6"
        >
            {error ? (
                <p className="text-red-400 text-sm flex items-center gap-1">
                    <AlertCircle className="w-4 h-4" />
                    {error}
                </p>
            ) : username && isAvailable === false ? (
                <p className="text-red-400 text-sm flex items-center gap-1">
                    <AlertCircle className="w-4 h-4" />
                    Username is already taken
                </p>
            ) : username && isAvailable ? (
                <p className="text-green-400 text-sm flex items-center gap-1">
                    <Check className="w-4 h-4" />
                    Username is available
                </p>
            ) : null}
        </motion.div>
    );
};

export default UsernamePickerPage;