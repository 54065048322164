import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import {
  Check, Plane, Globe, Building2,
  ArrowRight, ArrowLeft, AlertCircle, Sparkles
} from 'lucide-react';
import { authService } from '@/services/auth.service';
import { UserData } from '@/types';
import { airlineService } from '@/services/airline.service';

// Step indicators component
const StepIndicator = ({ currentStep, totalSteps }: { currentStep: number; totalSteps: number }) => {
  return (
    <div className="flex items-center justify-center gap-2 mb-8">
      {[...Array(totalSteps)].map((_, index) => (
        <div key={index} className="flex items-center">
          <div className={`
            w-8 h-8 rounded-full flex items-center justify-center
            transition-all duration-300
            ${index + 1 <= currentStep
              ? 'bg-gradient-to-r from-purple-500 to-pink-500 text-white'
              : 'bg-white/5 text-gray-400'}
          `}>
            {index + 1 <= currentStep ? (
              <Check className="w-4 h-4" />
            ) : (
              <span>{index + 1}</span>
            )}
          </div>
          {index < totalSteps - 1 && (
            <div className={`
              w-12 h-0.5 mx-1
              transition-all duration-300
              ${index + 1 < currentStep ? 'bg-gradient-to-r from-purple-500 to-pink-500' : 'bg-white/5'}
            `} />
          )}
        </div>
      ))}
    </div>
  );
};
// Main Setup Component
const AirlineSetup = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const totalSteps = 4;
  const [userData, setUserData] = useState<UserData | null>(null);
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (!storedUser) {
          navigate('/auth');
          return;
        }

        const user = JSON.parse(storedUser);
        const data = await authService.getUserData(user.uid);
        if (data) {
          setUserData(data);
        }
        console.log('User data loaded:', data);
      } catch (error) {
        console.error('Error loading user data:', error);
        navigate('/auth');
      }
    };

    loadUserData();
  }, [navigate]);

  enum AirlineAlliance {
    STAR_ALLIANCE = 'Star Alliance',
    ONEWORLD = 'Oneworld',
    SKYTEAM = 'SkyTeam',
    NONE = 'None'
  }

  interface FormData {
    name: string;
    code: string;
    callsign: string;
    alliance: AirlineAlliance;
    headquarters: {
      airport: string;
      city: string;
      country: string;
    };
    hubs: string[];
    focus_cities: string[];
  }

  const [formData, setFormData] = useState<FormData>({
    name: '',
    code: '',
    callsign: '',
    alliance: AirlineAlliance.NONE,
    headquarters: {
      airport: '',
      city: '',
      country: ''
    },
    hubs: [],
    focus_cities: []
  });
  const [errors, setErrors] = useState<{ name?: string; code?: string; callsign?: string; airport?: string; city?: string; country?: string; hubs?: string; submit?: string }>({});
  const [isLoading, setIsLoading] = useState(false);
  const [setupComplete, setSetupComplete] = useState(false);

  // Step content components
  const BasicInfo = () => (
    <div className="space-y-6">
      <div className="space-y-2">
        <label className="text-sm text-gray-400">Airline Name</label>
        <input
          type="text"
          value={formData.name}
          onChange={(e) => setFormData({ ...formData, name: e.target.value })}
          placeholder="Enter airline name"
          className="w-full px-4 py-3 rounded-xl bg-white/5 border border-white/10 text-white placeholder:text-gray-500 focus:border-purple-500 transition-all"
        />
        {errors.name && (
          <div className="flex items-center gap-1 text-red-400 text-sm">
            <AlertCircle className="w-4 h-4" />
            <span>{errors.name}</span>
          </div>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="text-sm text-gray-400">Airline Code (3 letters)</label>
          <input
            type="text"
            value={formData.code}
            onChange={(e) => setFormData({ ...formData, code: e.target.value.toUpperCase() })}
            placeholder="QFA"
            maxLength={3}
            className="w-full px-4 py-3 rounded-xl bg-white/5 border border-white/10 text-white placeholder:text-gray-500 focus:border-purple-500 transition-all uppercase"
          />
          {errors.code && (
            <div className="flex items-center gap-1 text-red-400 text-sm">
              <AlertCircle className="w-4 h-4" />
              <span>{errors.code}</span>
            </div>
          )}
        </div>

        <div className="space-y-2">
          <label className="text-sm text-gray-400">Call Sign (2 letters)</label>
          <input
            type="text"
            value={formData.callsign}
            onChange={(e) => setFormData({ ...formData, callsign: e.target.value.toUpperCase() })}
            placeholder="QF"
            maxLength={2}
            className="w-full px-4 py-3 rounded-xl bg-white/5 border border-white/10 text-white placeholder:text-gray-500 focus:border-purple-500 transition-all uppercase"
          />
          {errors.callsign && (
            <div className="flex items-center gap-1 text-red-400 text-sm">
              <AlertCircle className="w-4 h-4" />
              <span>{errors.callsign}</span>
            </div>
          )}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-sm text-gray-400">Alliance</label>
        <select>
          <option value={AirlineAlliance.NONE}>No Alliance</option>
          <option value={AirlineAlliance.STAR_ALLIANCE}>Star Alliance</option>
          <option value={AirlineAlliance.ONEWORLD}>Oneworld</option>
          <option value={AirlineAlliance.SKYTEAM}>SkyTeam</option>
        </select>
      </div>
    </div>
  );

  const HeadquartersInfo = () => (
    <div className="space-y-6">
      <div className="space-y-2">
        <label className="text-sm text-gray-400">Home Airport (IATA Code)</label>
        <input
          type="text"
          value={formData.headquarters.airport}
          onChange={(e) => setFormData({
            ...formData,
            headquarters: { ...formData.headquarters, airport: e.target.value.toUpperCase() }
          })}
          placeholder="SYD"
          maxLength={3}
          className="w-full px-4 py-3 rounded-xl bg-white/5 border border-white/10 text-white placeholder:text-gray-500 focus:border-purple-500 transition-all uppercase"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="text-sm text-gray-400">City</label>
          <input
            type="text"
            value={formData.headquarters.city}
            onChange={(e) => setFormData({
              ...formData,
              headquarters: { ...formData.headquarters, city: e.target.value }
            })}
            placeholder="Sydney"
            className="w-full px-4 py-3 rounded-xl bg-white/5 border border-white/10 text-white placeholder:text-gray-500 focus:border-purple-500 transition-all"
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm text-gray-400">Country</label>
          <input
            type="text"
            value={formData.headquarters.country}
            onChange={(e) => setFormData({
              ...formData,
              headquarters: { ...formData.headquarters, country: e.target.value }
            })}
            placeholder="Australia"
            className="w-full px-4 py-3 rounded-xl bg-white/5 border border-white/10 text-white placeholder:text-gray-500 focus:border-purple-500 transition-all"
          />
        </div>
      </div>
    </div>
  );

  const NetworkInfo = () => (
    <div className="space-y-6">
      <div className="space-y-2">
        <label className="text-sm text-gray-400">Hub Airports (IATA Codes)</label>
        <div className="flex items-center gap-2">
          <input
            type="text"
            placeholder="Add hub airport"
            className="flex-1 px-4 py-3 rounded-xl bg-white/5 border border-white/10 text-white placeholder:text-gray-500 focus:border-purple-500 transition-all"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && (e.target as HTMLInputElement).value) {
                setFormData({
                  ...formData,
                  hubs: [...formData.hubs, (e.target as HTMLInputElement).value.toUpperCase()]
                });
                (e.target as HTMLInputElement).value = '';
              }
            }}
          />
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
          {formData.hubs.map((hub, index) => (
            <motion.div
              key={hub}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="px-3 py-1 rounded-full bg-purple-500/20 border border-purple-500/30 text-purple-400 text-sm flex items-center gap-2"
            >
              {hub}
              <button
                onClick={() => setFormData({
                  ...formData,
                  hubs: formData.hubs.filter((_, i) => i !== index)
                })}
                className="hover:text-white transition-colors"
              >
                ×
              </button>
            </motion.div>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-sm text-gray-400">Focus Cities</label>
        <div className="flex items-center gap-2">
          <input
            type="text"
            placeholder="Add focus city"
            className="flex-1 px-4 py-3 rounded-xl bg-white/5 border border-white/10 text-white placeholder:text-gray-500 focus:border-purple-500 transition-all"
            onKeyDown={(e) => {
              if (e.key === 'Enter' && (e.target as HTMLInputElement).value) {
                setFormData({
                  ...formData,
                  focus_cities: [...formData.focus_cities, (e.target as HTMLInputElement).value]
                });
                (e.target as HTMLInputElement).value = '';
              }
            }}
          />
        </div>
        <div className="flex flex-wrap gap-2 mt-2">
          {formData.focus_cities.map((city, index) => (
            <motion.div
              key={city}
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              className="px-3 py-1 rounded-full bg-pink-500/20 border border-pink-500/30 text-pink-400 text-sm flex items-center gap-2"
            >
              {city}
              <button
                onClick={() => setFormData({
                  ...formData,
                  focus_cities: formData.focus_cities.filter((_, i) => i !== index)
                })}
                className="hover:text-white transition-colors"
              >
                ×
              </button>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );

  const ReviewInfo = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-2 gap-6">
        <div className="space-y-4">
          <div className="space-y-1">
            <label className="text-sm text-gray-400">Airline Name</label>
            <div className="text-lg text-white">{formData.name}</div>
          </div>

          <div className="space-y-1">
            <label className="text-sm text-gray-400">Airline Code</label>
            <div className="text-lg text-white">{formData.code}</div>
          </div>

          <div className="space-y-1">
            <label className="text-sm text-gray-400">Call Sign</label>
            <div className="text-lg text-white">{formData.callsign}</div>
          </div>

          <div className="space-y-1">
            <label className="text-sm text-gray-400">Alliance</label>
            <div className="text-lg text-white">{formData.alliance.replace('_', ' ')}</div>
          </div>
        </div>

        <div className="space-y-4">
          <div className="space-y-1">
            <label className="text-sm text-gray-400">Headquarters</label>
            <div className="text-lg text-white">
              {formData.headquarters.airport} - {formData.headquarters.city}, {formData.headquarters.country}
            </div>
          </div>

          <div className="space-y-1">
            <label className="text-sm text-gray-400">Hubs</label>
            <div className="flex flex-wrap gap-2">
              {formData.hubs.map(hub => (
                <span key={hub} className="px-2 py-1 rounded-lg bg-purple-500/20 text-purple-400">
                  {hub}
                </span>
              ))}
            </div>
          </div>

          <div className="space-y-1">
            <label className="text-sm text-gray-400">Focus Cities</label>
            <div className="flex flex-wrap gap-2">
              {formData.focus_cities.map(city => (
                <span key={city} className="px-2 py-1 rounded-lg bg-pink-500/20 text-pink-400">
                  {city}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const validateStep = (step: number) => {
    const newErrors: { name?: string; code?: string; callsign?: string; airport?: string; city?: string; country?: string; hubs?: string; submit?: string } = {};

    switch (step) {
      case 1:
        if (!formData.name.trim()) newErrors.name = 'Airline name is required';
        if (!formData.code) newErrors.code = 'Airline code is required';
        else if (!/^[A-Z]{3}$/.test(formData.code)) newErrors.code = 'Must be 3 uppercase letters';
        if (!formData.callsign) newErrors.callsign = 'Call sign is required';
        else if (!/^[A-Z]{2}$/.test(formData.callsign)) newErrors.callsign = 'Must be 2 uppercase letters';
        break;
      case 2:
        if (!formData.headquarters.airport) newErrors.airport = 'Home airport is required';
        if (!formData.headquarters.city) newErrors.city = 'City is required';
        if (!formData.headquarters.country) newErrors.country = 'Country is required';
        break;
      case 3:
        if (formData.hubs.length === 0) newErrors.hubs = 'At least one hub is required';
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = async () => {
    if (!validateStep(currentStep)) return;

    if (currentStep === totalSteps) {
      // Final submission
      if (!userData) {
        setErrors({ submit: 'User data not available. Please try again.' });
        return;
      }
      try {
        setIsLoading(true);
        const newAirline = {
          ...formData,
          ownerId: user.uid,
          ceoName: userData.username || 'CEO Name',
          stats: {
            fleetSize: 0,
            destinations: formData.hubs.length,
            dailyFlights: 0,
            averageLoadFactor: 0,
            punctuality: 100,
            reputation: 70
          },
          finances: {
            cash: 10000000, // Starting cash: $10M
            revenue: 0,
            expenses: 0,
            profit: 0
          },
          founded: new Date()
        };

        await airlineService.create(newAirline);
        setSetupComplete(true);
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An unexpected error occurred';
        setErrors({ submit: errorMessage });
      } finally {
        setIsLoading(false);
      }
    } else {
      setCurrentStep(prev => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(prev => prev - 1);
    }
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return <BasicInfo />;
      case 2:
        return <HeadquartersInfo />;
      case 3:
        return <NetworkInfo />;
      case 4:
        return <ReviewInfo />;
      default:
        return null;
    }
  };

  const renderStepIcon = () => {
    switch (currentStep) {
      case 1:
        return <Plane className="w-8 h-8" />;
      case 2:
        return <Building2 className="w-8 h-8" />;
      case 3:
        return <Globe className="w-8 h-8" />;
      case 4:
        return <Sparkles className="w-8 h-8" />;
      default:
        return null;
    }
  };

  if (setupComplete) {
    return (
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        className="fixed inset-0 flex items-center justify-center bg-black/50 backdrop-blur-sm"
      >
        <div className="bg-[#0A0A1E]/90 backdrop-blur-xl border border-white/10 rounded-2xl p-8 max-w-lg w-full mx-4">
          <div className="text-center">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="w-16 h-16 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mx-auto mb-6"
            >
              <Check className="w-8 h-8 text-white" />
            </motion.div>

            <h2 className="text-2xl font-bold text-white mb-2">
              Congratulations! 🎉
            </h2>

            <p className="text-gray-400 mb-6">
              Your airline {formData.name} ({formData.code}) has been successfully created.
              You're ready to start building your aviation empire!
            </p>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={() => window.location.href = '/dashboard'}
              className="w-full py-3 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium"
            >
              Go to Dashboard
            </motion.button>
          </div>
        </div>
      </motion.div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0A0A1E] flex items-center justify-center p-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="w-full max-w-3xl"
      >
        <div className="bg-black/40 backdrop-blur-xl border border-white/10 rounded-2xl p-8">
          {/* Header */}
          <div className="text-center mb-8">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="w-16 h-16 rounded-full bg-gradient-to-r from-purple-500 to-pink-500 flex items-center justify-center mx-auto mb-4"
            >
              {renderStepIcon()}
            </motion.div>

            <h2 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
              {currentStep === totalSteps ? 'Review Your Airline' : 'Create Your Airline'}
            </h2>

            <p className="text-gray-400 mt-2">
              Step {currentStep} of {totalSteps}: {
                currentStep === 1 ? 'Basic Information' :
                  currentStep === 2 ? 'Headquarters Location' :
                    currentStep === 3 ? 'Network Planning' :
                      'Final Review'
              }
            </p>
          </div>

          {/* Step Indicators */}
          <StepIndicator currentStep={currentStep} totalSteps={totalSteps} />

          {/* Step Content */}
          <div className="mb-8">
            <AnimatePresence mode="wait">
              <motion.div
                key={currentStep}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                transition={{ duration: 0.3 }}
              >
                {renderStepContent()}
              </motion.div>
            </AnimatePresence>
          </div>

          {/* Error Message */}
          {errors.submit && (
            <div className="mb-6 p-4 rounded-xl bg-red-500/10 border border-red-500/30 text-red-400">
              <div className="flex items-center gap-2">
                <AlertCircle className="w-5 h-5" />
                <span>{errors.submit}</span>
              </div>
            </div>
          )}

          {/* Navigation Buttons */}
          <div className="flex items-center justify-between gap-4">
            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleBack}
              disabled={currentStep === 1}
              className={`
                flex items-center gap-2 px-6 py-3 rounded-xl font-medium
                transition-colors duration-200
                ${currentStep === 1
                  ? 'opacity-50 cursor-not-allowed bg-white/5 text-gray-400'
                  : 'bg-white/5 text-white hover:bg-white/10'}
              `}
            >
              <ArrowLeft className="w-4 h-4" />
              Back
            </motion.button>

            <motion.button
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
              onClick={handleNext}
              disabled={isLoading}
              className="flex-1 sm:flex-none flex items-center justify-center gap-2 px-8 py-3 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 text-white font-medium disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {isLoading ? (
                <motion.div
                  className="w-5 h-5 border-2 border-white border-t-transparent rounded-full"
                  animate={{ rotate: 360 }}
                  transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                />
              ) : (
                <>
                  {currentStep === totalSteps ? 'Create Airline' : 'Next'}
                  <ArrowRight className="w-4 h-4" />
                </>
              )}
            </motion.button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AirlineSetup;