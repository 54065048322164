import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plane, Globe, DollarSign, Users, Building2, ChevronUp, Bell, Rocket } from 'lucide-react';
import { Header, Footer } from '@/components/ui/FootHead';

const LandingPage = () => {
  const [, setIsVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [daysLeft, setDaysLeft] = useState(0);

  useEffect(() => {
    setIsVisible(true);
    const handleScroll = () => setScrollPosition(window.scrollY);
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const targetDate = new Date('2025-06-01');
    const interval = setInterval(() => {
      const now = new Date();
      const timeDifference = targetDate.getTime() - now.getTime();
      const monthsLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24 * 30));
      setDaysLeft(monthsLeft);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const features = [
    {
      icon: <Plane className="w-16 h-16" />,
      title: "Fleet Management",
      description: "Purchase, lease, and sell aircraft while maintaining their condition and customizing configurations to optimize your airline's performance.",
      gradient: "from-violet-500 to-indigo-500",
      preview: "/api/placeholder/600/400"
    },
    {
      icon: <Globe className="w-16 h-16" />,
      title: "Route Planning",
      description: "Create and manage flight routes, set ticket prices, analyze profitability, and handle airport slots to expand your global aviation empire.",
      gradient: "from-fuchsia-500 to-pink-500",
      preview: "/api/placeholder/600/400"
    },
    {
      icon: <DollarSign className="w-16 h-16" />,
      title: "Financial Management",
      description: "Track revenue and expenses, manage loans and capital, monitor cash flow, and handle fuel price fluctuations to maintain financial stability.",
      gradient: "from-rose-500 to-red-500",
      preview: "/api/placeholder/600/400"
    },
    {
      icon: <Users className="w-16 h-16" />,
      title: "Staff Management",
      description: "Hire and train pilots, cabin crew, and ground staff, manage salaries and working hours, and ensure staff satisfaction to build a reliable workforce.",
      gradient: "from-green-500 to-teal-500",
      preview: "/api/placeholder/600/400"
    },
    {
      icon: <Building2 className="w-16 h-16" />,
      title: "Airport Operations",
      description: "Establish airport hubs, manage terminal gates and facilities, handle ground services, and negotiate fees to streamline your airport operations.",
      gradient: "from-blue-500 to-indigo-600",
      preview: "/api/placeholder/600/400"
    },
    {
      icon: <Bell className="w-16 h-16" />,
      title: "Customer Service",
      description: "Monitor passenger satisfaction, handle complaints and delays, improve service quality, and manage your airline's reputation for success.",
      gradient: "from-yellow-500 to-orange-500",
      preview: "/api/placeholder/600/400"
    }
  ];

  return (
    <div className="min-h-screen bg-[#0A0A1E] overflow-hidden">
      {/* Enhanced Animated Background */}
      <div className="fixed inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff10_1px,transparent_1px),linear-gradient(to_bottom,#ffffff10_1px,transparent_1px)] bg-[size:14px_24px] opacity-20" />
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-purple-900/30 via-transparent to-blue-900/30"
          animate={{
            background: [
              "linear-gradient(to bottom right, rgba(88,28,135,0.3), transparent, rgba(29,78,216,0.3))",
              "linear-gradient(to bottom right, rgba(29,78,216,0.3), transparent, rgba(88,28,135,0.3))"
            ]
          }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
      </div>

      <Header />

      {/* Enhanced Hero Section */}
      <div className="relative pt-32 pb-20">
        <motion.div
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="text-center relative z-10">
            <motion.div
              initial={{ scale: 0.8, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.8 }}
              className="inline-block mb-4"
            >
              <div className="px-6 py-2 rounded-full bg-white/10 backdrop-blur-lg border border-white/20">
                <div className="flex items-center space-x-2">
                  <Rocket className="w-5 h-5 text-purple-400" />
                  <span className="text-gray-300">Coming Soon</span>
                  <span className="px-2 py-1 rounded-full bg-purple-500 text-white text-sm">
                    {daysLeft} Months Left
                  </span>
                </div>
              </div>
            </motion.div>

            <motion.h1
              className="text-7xl md:text-9xl font-bold mb-8 tracking-tight"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-400 to-red-400 animate-gradient-x">
                SkyManager
              </span>
            </motion.h1>

            <motion.p
              className="text-2xl md:text-3xl text-gray-300 mb-12 max-w-4xl mx-auto leading-relaxed"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              The next evolution in airline management simulation.
              <br />
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
                Coming Q2 2025
              </span>
            </motion.p>

            {/* Feature Preview */}
            <div className="relative mt-20">
              <motion.div
                className="relative z-10 grid grid-cols-1 md:grid-cols-3 gap-8"
                initial={{ y: 40, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.8, delay: 0.8 }}
              >
                {features.map((feature, index) => (
                  <motion.div
                    key={index}
                    className="group relative"
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 rounded-2xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity" />
                    <div className="relative p-8 rounded-2xl bg-white/5 backdrop-blur-lg border border-white/10">
                      <div className={`inline-block p-4 rounded-xl text-white mb-6 bg-gradient-to-br ${feature.gradient}`}>
                        {feature.icon}
                      </div>
                      <h3 className="text-2xl font-bold text-white mb-4">{feature.title}</h3>
                      <p className="text-gray-300">{feature.description}</p>
                    </div>
                  </motion.div>
                ))}
              </motion.div>
            </div>
          </div>
        </motion.div>
      </div>
      <Footer />

      {/* Enhanced Floating Action Button */}
      <AnimatePresence>
        {scrollPosition > 500 && (
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 100, opacity: 0 }}
            className="fixed bottom-8 right-8"
          >
            <button
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="group bg-gradient-to-r from-purple-500 to-pink-500 p-4 rounded-full hover:shadow-lg hover:shadow-purple-500/25 transition-all"
            >
              <ChevronUp className="w-6 h-6 text-white transform group-hover:-translate-y-1 transition-transform" />
            </button>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default LandingPage;