// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './index.css';
import LandingPage from './pages/LandingPage';
import { AuthPage } from './pages/AuthPage';
import MainGame from './pages/Game/MainPage';
import AboutPage from './pages/AboutPage';
import AccountPage from './pages/AccountPage';
import AirlineSetup from './pages/Game/NewAirline';
import { AuthAction } from './pages/AuthAction';
import { AuthHandler } from './pages/AuthHandler';
import UsernamePickerPage from './pages/UsernamePicker';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/auth" element={<AuthPage />} />
        <Route path="/game" element={<MainGame />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/account" element={<AccountPage />} />
        <Route path="/new" element={<AirlineSetup />} />
        <Route path="/auth/action" element={<AuthAction />} />
        <Route path="/__/auth/handler" element={<AuthHandler />} />
        <Route path="/username" element={<UsernamePickerPage />} />
      </Routes>
    </Router>
  );
}

export default App;