import React from 'react';
import { motion } from 'framer-motion';
import { Header, Footer } from '@/components/ui/FootHead';
import {
  Target,
  Shield,
  HeartHandshake,
} from 'lucide-react';

const AboutPage = () => {
  const teamMembers = [
    {
      name: "Sarah Chen",
      role: "Lead Developer",
      avatar: "/api/placeholder/100/100",
      description: "10+ years of game development experience"
    },
    {
      name: "Alex Rodriguez",
      role: "Aviation Expert",
      avatar: "/api/placeholder/100/100",
      description: "Former airline operations manager"
    },
    {
      name: "Marie Laurent",
      role: "UI/UX Designer",
      avatar: "/api/placeholder/100/100",
      description: "Award-winning interface designer"
    }
  ];

  const values = [
    {
      icon: Shield,
      title: "Quality First",
      description: "We never compromise on the quality of our simulation experience"
    },
    {
      icon: HeartHandshake,
      title: "Community Driven",
      description: "Our players' feedback shapes the future of SkyManager"
    },
    {
      icon: Target,
      title: "Attention to Detail",
      description: "Every aspect of airline management is carefully simulated"
    }
  ];

  const stats = [
    { value: "2+", label: "Years in Development" },
    { value: "50K+", label: "Lines of Code" },
    { value: "1.2K+", label: "Discord Members" },
    { value: "24/7", label: "Community Support" }
  ];

  return (
    <div className="min-h-screen bg-[#0A0A1E] overflow-hidden">
      {/* Enhanced Animated Background */}
      <div className="fixed inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff10_1px,transparent_1px),linear-gradient(to_bottom,#ffffff10_1px,transparent_1px)] bg-[size:14px_24px] opacity-20" />
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-purple-900/30 via-transparent to-blue-900/30"
          animate={{
            background: [
              "linear-gradient(to bottom right, rgba(88,28,135,0.3), transparent, rgba(29,78,216,0.3))",
              "linear-gradient(to bottom right, rgba(29,78,216,0.3), transparent, rgba(88,28,135,0.3))"
            ]
          }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
      </div>

      <Header />

      <main className="relative pt-32 pb-20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Hero Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-20"
          >
            <motion.h1
              className="text-7xl font-bold mb-6"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
            >
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-400 to-red-400">
                About SkyManager
              </span>
            </motion.h1>
            <motion.p
              className="text-xl text-gray-300 max-w-3xl mx-auto"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.8, delay: 0.4 }}
            >
              Elevating airline management simulation to new heights through
              innovation, attention to detail, and community engagement.
            </motion.p>
          </motion.div>

          {/* Stats Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.6 }}
            className="grid grid-cols-4 gap-6 mb-20"
          >
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.05 }}
                className="relative group"
              >
                <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 rounded-2xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity" />
                <div className="relative p-8 rounded-2xl bg-white/5 backdrop-blur-lg border border-white/10 text-center">
                  <motion.h3
                    className="text-4xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400"
                    initial={{ scale: 0.5 }}
                    animate={{ scale: 1 }}
                    transition={{ delay: 0.2 * index }}
                  >
                    {stat.value}
                  </motion.h3>
                  <p className="text-gray-400 mt-2">{stat.label}</p>
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* Values Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.8 }}
            className="mb-20"
          >
            <h2 className="text-3xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
              Our Values
            </h2>
            <div className="grid grid-cols-3 gap-8">
              {values.map((value, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  className="relative group"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 rounded-2xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity" />
                  <div className="relative p-8 rounded-2xl bg-white/5 backdrop-blur-lg border border-white/10 text-center">
                    <value.icon className="w-12 h-12 text-purple-400 mx-auto mb-4" />
                    <h3 className="text-xl font-bold text-white mb-2">{value.title}</h3>
                    <p className="text-gray-400">{value.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Team Section */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 1 }}
          >
            <h2 className="text-3xl font-bold text-center mb-12 text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400">
              Meet the Team
            </h2>
            <div className="grid grid-cols-3 gap-8">
              {teamMembers.map((member, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  className="relative group"
                >
                  <div className="absolute inset-0 bg-gradient-to-br from-purple-500/20 to-pink-500/20 rounded-2xl blur-xl opacity-0 group-hover:opacity-100 transition-opacity" />
                  <div className="relative p-8 rounded-2xl bg-white/5 backdrop-blur-lg border border-white/10 text-center">
                    <img
                      src={member.avatar}
                      alt={member.name}
                      className="w-24 h-24 rounded-full mx-auto mb-4 border-2 border-purple-500"
                    />
                    <h3 className="text-xl font-bold text-white mb-1">{member.name}</h3>
                    <p className="text-purple-400 mb-2">{member.role}</p>
                    <p className="text-gray-400 text-sm">{member.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </div>
      </main>

      <Footer />
    </div>
  );
};

export default AboutPage;