import React, { useEffect, useState, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Check, AlertCircle, Loader2 } from 'lucide-react';
import {
  applyActionCode,
  confirmPasswordReset,
  verifyPasswordResetCode
} from 'firebase/auth';
import { auth } from '../lib/firebase';

interface AuthActionState {
  loading: boolean;
  error: string | null;
  success: boolean;
  email: string | null;
}


export const AuthAction = () => {
  const [searchParams] = useSearchParams();
  const [state, setState] = useState<AuthActionState>({
    loading: true,
    error: null,
    success: false,
    email: null
  });
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const mode = searchParams.get('mode');
  const actionCode = searchParams.get('oobCode');

  const handleAuthAction = useCallback(async () => {
    if (!actionCode || !mode) return;

    try {
      switch (mode) {
        case 'resetPassword':
          // Verify the password reset code is valid
          const email = await verifyPasswordResetCode(auth, actionCode);
          setState({
            loading: false,
            error: null,
            success: false,
            email
          });
          break;

        case 'verifyEmail':
          await applyActionCode(auth, actionCode);
          setState({
            loading: false,
            error: null,
            success: true,
            email: null
          });
          break;

        default:
          setState({
            loading: false,
            error: 'Invalid action',
            success: false,
            email: null
          });
      }
    } catch (error: any) {
      setState({
        loading: false,
        error: error.message,
        success: false,
        email: null
      });
    }
  }, [actionCode, mode]);

  useEffect(() => {
    if (!actionCode) {
      setState({
        loading: false,
        error: 'Invalid action code',
        success: false,
        email: null
      });
      return;
    }

    handleAuthAction();
  }, [actionCode, mode, handleAuthAction]);

  const handlePasswordReset = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!actionCode) return;

    if (newPassword !== confirmPassword) {
      setState(prev => ({
        ...prev,
        error: 'Passwords do not match'
      }));
      return;
    }

    setState(prev => ({ ...prev, loading: true }));

    try {
      await confirmPasswordReset(auth, actionCode, newPassword);
      setState(prev => ({
        ...prev,
        loading: false,
        success: true,
        error: null
      }));
    } catch (error: any) {
      setState(prev => ({
        ...prev,
        loading: false,
        error: error.message,
        success: false
      }));
    }
  };

  const renderContent = () => {
    if (state.loading) {
      return (
        <div className="flex flex-col items-center gap-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="p-4 rounded-full bg-purple-500/10"
          >
            <Loader2 className="w-8 h-8 text-purple-500 animate-spin" />
          </motion.div>
          <p className="text-gray-400">Processing your request...</p>
        </div>
      );
    }

    if (state.error) {
      return (
        <div className="flex flex-col items-center gap-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="p-4 rounded-full bg-red-500/10"
          >
            <AlertCircle className="w-8 h-8 text-red-500" />
          </motion.div>
          <p className="text-red-400">{state.error}</p>
          <button
            onClick={() => window.location.href = '/'}
            className="px-6 py-2 text-white bg-red-500 rounded-lg hover:bg-red-600 transition-colors"
          >
            Return to Home
          </button>
        </div>
      );
    }

    if (state.success) {
      return (
        <div className="flex flex-col items-center gap-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            className="p-4 rounded-full bg-green-500/10"
          >
            <Check className="w-8 h-8 text-green-500" />
          </motion.div>
          <p className="text-green-400">
            {mode === 'resetPassword'
              ? 'Your password has been successfully reset.'
              : 'Your email has been verified.'}
          </p>
          <button
            onClick={() => window.location.href = '/auth?mode=signin'}
            className="px-6 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600 transition-colors"
          >
            Sign In
          </button>
        </div>
      );
    }

    if (mode === 'resetPassword' && state.email) {
      return (
        <form onSubmit={handlePasswordReset} className="space-y-6">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-white mb-2">Reset Password</h2>
            <p className="text-gray-400">
              Enter a new password for {state.email}
            </p>
          </div>

          <div className="space-y-4">
            <div>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:border-purple-500"
                placeholder="New Password"
                minLength={8}
                required
              />
            </div>
            <div>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg text-white focus:outline-none focus:border-purple-500"
                placeholder="Confirm New Password"
                minLength={8}
                required
              />
            </div>
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-gradient-to-r from-purple-500 to-pink-500 text-white rounded-lg hover:shadow-lg hover:shadow-purple-500/25 transition-all"
          >
            Reset Password
          </button>
        </form>
      );
    }

    return null;
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#0A0A1E] p-4">
      <div className="w-full max-w-md p-8 bg-black/40 backdrop-blur-xl border border-white/10 rounded-2xl">
        {renderContent()}
      </div>
    </div>
  );
};