import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { GameHeader, GameFooter } from './Components/HeadFoot';
import Dashboard from './Pages/Dashboard';
import ScrollableContainer from '@/components/ui/ScrollableContainer';

// Page transition variants
const pageVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  enter: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      ease: 'easeOut',
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },
};

// Placeholder components for other pages
const FleetPage = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-white mb-4">Fleet Management</h2>
    {/* Add fleet management content */}
  </div>
);

const RoutesPage = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-white mb-4">Route Network</h2>
    {/* Add routes management content */}
  </div>
);

const StaffPage = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-white mb-4">Staff Management</h2>
    {/* Add staff management content */}
  </div>
);

const FinancePage = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-white mb-4">Financial Overview</h2>
    {/* Add financial management content */}
  </div>
);

const AirportsPage = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-white mb-4">Airport Operations</h2>
    {/* Add airport management content */}
  </div>
);

const MarketPage = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-white mb-4">Market Analysis</h2>
    {/* Add market analysis content */}
  </div>
);

const SafetyPage = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-white mb-4">Safety & Compliance</h2>
    {/* Add safety management content */}
  </div>
);

const SupportPage = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-white mb-4">Support Center</h2>
    {/* Add support content */}
  </div>
);

const SettingsPage = () => (
  <div className="p-6">
    <h2 className="text-2xl font-bold text-white mb-4">Game Settings</h2>
    {/* Add settings content */}
  </div>
);

const MainGame = () => {
  const [currentPage, setCurrentPage] = useState('dashboard');

  const renderPage = () => {
    switch (currentPage) {
      case 'dashboard':
        return <Dashboard />;
      case 'fleet':
        return <FleetPage />;
      case 'routes':
        return <RoutesPage />;
      case 'staff':
        return <StaffPage />;
      case 'finance':
        return <FinancePage />;
      case 'airports':
        return <AirportsPage />;
      case 'market':
        return <MarketPage />;
      case 'safety':
        return <SafetyPage />;
      case 'support':
        return <SupportPage />;
      case 'settings':
        return <SettingsPage />;
      default:
        return <Dashboard />;
    }
  };

  const handlePageChange = (page: string) => {
    setCurrentPage(page);
  };

  return (
    <div className="min-h-screen bg-[#0A0A1E] overflow-hidden">
      {/* Game Header */}
      <GameHeader onTabChange={handlePageChange} activeTab={currentPage} />

      {/* Main Content Area */}
      <main className="mt-1 pt-24">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentPage}
            initial="initial"
            animate="enter"
            exit="exit"
            variants={pageVariants}
          >
            <ScrollableContainer className="h-[calc(100vh-9.9rem)] overflow-y-auto">
              {renderPage()}
            </ScrollableContainer>
          </motion.div>
        </AnimatePresence>
      </main>

      {/* Game Footer */}
      <GameFooter />
    </div>
  );
};

export default MainGame;