import React, { useState, useEffect } from 'react';
import { Menu, X, Twitter, Github, MessageCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

export const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  useEffect(() => {
    setIsAuthenticated(!!localStorage.getItem('user'));
  }, []);

  const NavigationButton: React.FC<{ href?: string; isActive?: boolean; children: React.ReactNode }> = ({ children, href = "#", isActive = false }) => (
    <a
      href={href}
      className={`
        relative px-6 py-2 rounded-lg transition-all duration-300
        ${isActive ? 'text-white' : 'text-gray-400 hover:text-white'}
        group
      `}
    >
      {children}
      <div className={`
        absolute bottom-0 left-0 w-full h-0.5 transform origin-left scale-x-0
        bg-gradient-to-r from-purple-500 to-pink-500 transition-transform duration-300
        ${isActive ? 'scale-x-100' : 'group-hover:scale-x-100'}
      `} />
    </a>
  );

  const handleGameNavigation = () => {
    navigate('/game');
  };

  return (
    <>
      <header className={`
        fixed w-full z-50 transition-all duration-300
        ${scrollPosition > 50 ? 'bg-black/50 backdrop-blur-lg' : ''}
      `}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <nav className="flex items-center justify-between h-20">
            {/* Logo */}
            <div className="flex items-center space-x-8">
              <a href="/" className="flex items-center space-x-2">
                <img src="/SkyManagerLogo.png" alt="SkyManager Logo" className="w-10 h-10 rounded-lg" />
                <span className="text-white font-bold text-xl">SkyManager</span>
              </a>

              {/* Desktop Navigation */}
              <div className="hidden md:flex items-center space-x-1">
                {[
                  { name: 'Features', href: '/features' },
                  { name: 'Pricing', href: '/pricing' },
                  { name: 'About', href: '/about' },
                  { name: 'Contact', href: '/contact' }
                ].map((item) => (
                  <NavigationButton key={item.name} href={item.href}>
                    {item.name}
                  </NavigationButton>
                ))}
              </div>
            </div>

            {/* Desktop CTA Buttons */}
            <div className="hidden md:flex items-center space-x-4">
              {isAuthenticated ? (
                <button
                  onClick={handleGameNavigation}
                  className="
                    px-6 py-2 rounded-lg text-white font-medium
                    bg-gradient-to-r from-purple-500 to-pink-500
                    hover:shadow-lg hover:shadow-purple-500/25 
                    transition-all transform hover:scale-105
                    flex items-center gap-2
                  "
                >
                  Go to Game
                </button>
              ) : (
                <>
                  <button
                    className="px-4 py-2 text-gray-300 hover:text-white transition-colors"
                    onClick={() => navigate('/auth?mode=signin')}
                  >
                    Sign In
                  </button>
                  <button
                    className="bg-gradient-to-r from-purple-500 to-pink-500 px-6 py-2 rounded-lg text-white font-medium hover:shadow-lg hover:shadow-purple-500/25 transition-all transform hover:scale-105"
                    onClick={() => navigate('/auth?mode=signup')}
                  >
                    Get Started
                  </button>
                </>
              )}
            </div>

            {/* Mobile Menu Button */}
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="md:hidden text-white p-2"
            >
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </nav>

          {/* Mobile Navigation */}
          <div className={`
            md:hidden absolute top-full left-0 w-full 
            bg-black/95 backdrop-blur-lg border-t border-white/10
            transition-all duration-300 transform
            ${isMenuOpen ? 'translate-y-0 opacity-100' : '-translate-y-4 opacity-0 pointer-events-none'}
          `}>
            <div className="px-4 py-6 space-y-4">
              {[
                { name: 'Features', href: '/features' },
                { name: 'Pricing', href: '/pricing' },
                { name: 'About', href: '/about' },
                { name: 'Contact', href: '/contact' }
              ].map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="block px-4 py-2 text-gray-300 hover:text-white transition-colors"
                >
                  {item.name}
                </a>
              ))}
              <div className="pt-4 border-t border-white/10">
                {isAuthenticated ? (
                  <button
                    className="w-full bg-gradient-to-r from-purple-500 to-pink-500 px-4 py-2 rounded-lg text-white font-medium"
                    onClick={handleGameNavigation}
                  >
                    Go to Game
                  </button>
                ) : (
                  <>
                    <button
                      className="w-full px-4 py-2 text-gray-300 hover:text-white transition-colors"
                      onClick={() => navigate('/auth?mode=signin')}
                    >
                      Sign In
                    </button>
                    <button
                      className="w-full mt-2 bg-gradient-to-r from-purple-500 to-pink-500 px-4 py-2 rounded-lg text-white font-medium"
                      onClick={() => navigate('/auth?mode=signup')}
                    >
                      Get Started
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Backdrop for mobile menu */}
      {isMenuOpen && (
        <div
          className="fixed inset-0 bg-black/50 backdrop-blur-sm z-40 md:hidden"
          onClick={() => setIsMenuOpen(false)}
        />
      )}
    </>
  );
};

export const Footer = () => {
  const footerSections = [
    {
      title: 'Product',
      links: ['Features', 'Pricing', 'Tutorial']
    },
    {
      title: 'Company',
      links: ['About', 'Careers', 'Press']
    },
    {
      title: 'Resources',
      links: ['Documentation', 'Support', 'Status']
    },
    {
      title: 'Legal',
      links: ['Privacy', 'Terms', 'Security']
    }
  ];

  const socialLinks = [
    { name: 'Twitter', icon: Twitter },
    { name: 'GitHub', icon: Github },
    { name: 'Discord', icon: MessageCircle }
  ];

  return (
    <footer className="relative border-t border-white/10">
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-purple-900/10" />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6 mb-8">
          {footerSections.map((section) => (
            <div key={section.title}>
              <h3 className="text-white text-sm font-semibold mb-3">{section.title}</h3>
              <ul className="space-y-2">
                {section.links.map((link) => (
                  <li key={link}>
                    <button className="text-sm text-gray-400 hover:text-white transition-colors">
                      {link}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        <div className="border-t border-white/10 pt-6 flex flex-col md:flex-row items-center justify-between gap-4">
          <div className="flex items-center gap-4">
            <img src="/SkyManagerLogo.png" alt="SkyManager" className="w-8 h-8 rounded-lg" />
            <div className="flex flex-col md:flex-row items-center gap-4">
              <span className="text-sm text-gray-400">
                © 2024 SkyManager. All rights reserved.
              </span>
              <div className="flex items-center gap-4 text-sm">
                <button className="text-gray-400 hover:text-white transition-colors">
                  Privacy
                </button>
                <span className="text-gray-600">•</span>
                <button className="text-gray-400 hover:text-white transition-colors">
                  Terms
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center gap-4">
            {socialLinks.map((social) => (
              <button
                key={social.name}
                className="p-2 rounded-lg text-gray-400 hover:text-white hover:bg-white/5 transition-colors"
                aria-label={social.name}
              >
                <social.icon className="w-4 h-4" />
              </button>
            ))}
            <button className="
                ml-2 px-4 py-1.5 rounded-lg text-sm font-medium
                bg-gradient-to-r from-purple-500 to-pink-500
                text-white hover:shadow-lg hover:shadow-purple-500/25
                transition-all transform hover:scale-105
              ">
              Get Started
            </button>
          </div>
        </div>
      </div>
    </footer>
  );
};