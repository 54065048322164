import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyBN5kvfgP_TnvpuhOsdY5Ac9exMGYp-Ryc",
  authDomain: "skymanager.app",
  projectId: "sky-manager-app",
  storageBucket: "sky-manager-app.firebasestorage.app",
  messagingSenderId: "356487534792",
  appId: "1:356487534792:web:78b1342d7434876e206dfd",
  measurementId: "G-T2R6F845PR"
};


export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);