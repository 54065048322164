import {
  auth,
  db
} from '../lib/firebase';
import { AuthError } from '../lib/errors';
import {
  UserData,
  UserRole,
  SubscriptionPlan
} from '../types';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
  User,
  UserCredential,
  GoogleAuthProvider
} from 'firebase/auth';
import {
  doc,
  getDoc,
  setDoc,
  writeBatch,
  runTransaction
} from 'firebase/firestore';

class AuthService {
  private readonly USERS_COLLECTION = 'users';
  private readonly USERNAMES_COLLECTION = 'usernames';
  private readonly googleProvider: GoogleAuthProvider;
  private readonly CUSTOM_HANDLER_URL: string;

  constructor() {
    this.googleProvider = new GoogleAuthProvider();
    this.googleProvider.addScope('profile');
    this.googleProvider.addScope('email');

    // Set custom handler URL
    this.CUSTOM_HANDLER_URL = `${window.location.origin}/__/auth/handler`;

    // Configure custom parameters for Google sign-in
    this.googleProvider.setCustomParameters({
      prompt: 'select_account',
      redirect_uri: this.CUSTOM_HANDLER_URL
    });
  }

  public validateUsername(username: string): boolean {
    // Username should be 3-20 characters, alphanumeric and underscores only
    const regex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!regex.test(username)) {
      return false;
    }

    // Additional checks
    const normalized = username.toLowerCase();

    // Prevent reserved usernames
    const reservedNames = [
      'admin', 'administrator', 'mod', 'moderator', 'support',
      'help', 'system', 'bot', 'staff', 'team', 'official',
      'settings', 'login', 'logout', 'signin', 'signout',
      'signup', 'register', 'dashboard'
    ];

    if (reservedNames.includes(normalized)) {
      return false;
    }

    // Prevent usernames with special patterns
    if (username.includes('__') || username.startsWith('_') || username.endsWith('_')) {
      return false;
    }

    return true;
  }

  public async isUsernameTaken(username: string): Promise<boolean> {
    try {
      const normalized = username.toLowerCase();
      const usernameDoc = await getDoc(doc(db, this.USERNAMES_COLLECTION, normalized));
      return usernameDoc.exists();
    } catch (error) {
      throw new AuthError(
        'Unable to verify username availability',
        'auth/username-check-failed'
      );
    }
  }

  private generateUsername(displayName: string | null): string {
    // Create a base username from display name or 'user'
    let baseUsername = displayName
      ? displayName.toLowerCase().replace(/[^a-z0-9]/g, '')
      : 'user';

    // Ensure minimum length
    if (baseUsername.length < 3) {
      baseUsername = baseUsername.padEnd(3, '0');
    }

    // Truncate if too long
    if (baseUsername.length > 16) {
      baseUsername = baseUsername.substring(0, 16);
    }

    // Add random numbers
    const randomSuffix = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
    return `${baseUsername}${randomSuffix}`;
  }

  async signInWithGoogle(): Promise<UserCredential> {
    let popup: Window | null = null;
    let checkClosedInterval: NodeJS.Timer | null = null;
    let timeoutId: NodeJS.Timeout | null = null;

    try {
      const popupPromise = new Promise<UserCredential>((resolve, reject) => {
        let messageReceived = false;

        const messageHandler = async (event: MessageEvent) => {
          if (event.origin !== window.location.origin) return;
          if (messageReceived) return;

          if (event.data.type === 'AUTH_COMPLETE') {
            messageReceived = true;

            // Clean up
            window.removeEventListener('message', messageHandler);
            if (checkClosedInterval) clearInterval(checkClosedInterval);
            if (timeoutId) clearTimeout(timeoutId);

            if (event.data.success) {
              try {
                const userData = event.data.user;
                const userDoc = await getDoc(doc(db, this.USERS_COLLECTION, userData.uid));

                if (!userDoc.exists()) {
                  await this.createNewGoogleUser(userData);
                } else {
                  await this.updateExistingUser(userData);
                }

                localStorage.setItem('user', JSON.stringify(userData));

                const userCredential = {
                  user: userData,
                  providerId: 'google.com',
                  operationType: 'signIn'
                } as UserCredential;

                resolve(userCredential);
              } catch (error) {
                reject(error);
              }
            } else {
              reject(new Error(event.data.error || 'Authentication failed'));
            }
          }
        };

        window.addEventListener('message', messageHandler);

        // Set timeout
        timeoutId = setTimeout(() => {
          if (!messageReceived) {
            window.removeEventListener('message', messageHandler);
            if (checkClosedInterval) clearInterval(checkClosedInterval);
            if (popup !== null) popup.close();
            reject(new Error('Authentication timed out'));
          }
        }, 120000);

        // Store return URL
        localStorage.setItem('authReturnUrl', window.location.pathname);

        // Open popup
        try {
          const width = 500;
          const height = 600;
          const left = window.screen.width / 2 - width / 2;
          const top = window.screen.height / 2 - height / 2;

          popup = window.open(
            `https://accounts.google.com/o/oauth2/auth?` +
            `client_id=${process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}` +
            `&redirect_uri=${encodeURIComponent(this.CUSTOM_HANDLER_URL)}` +
            `&response_type=code` +
            `&scope=${encodeURIComponent('profile email')}` +
            `&prompt=select_account`,
            'googleSignIn',
            `width=${width},height=${height},left=${left},top=${top}`
          );

          if (!popup) {
            throw new Error('Popup blocked. Please allow popups and try again.');
          }

          // Check if popup is closed
          checkClosedInterval = setInterval(() => {
            if (popup?.closed && !messageReceived) {
              clearInterval(checkClosedInterval!);
              if (timeoutId) clearTimeout(timeoutId);
              window.removeEventListener('message', messageHandler);
              reject(new AuthError('Sign-in cancelled', 'auth/popup-closed-by-user'));
            }
          }, 1000);

        } catch (error) {
          window.removeEventListener('message', messageHandler);
          if (timeoutId) clearTimeout(timeoutId);
          reject(error);
        }
      });

      return await popupPromise;

    } catch (error: any) {
      // Clean up
      if (checkClosedInterval) clearInterval(checkClosedInterval);
      if (timeoutId) clearTimeout(timeoutId);
      if (popup) (popup as Window).close();
      localStorage.removeItem('authReturnUrl');

      throw new AuthError(
        this.getReadableErrorMessage(error),
        error.code || 'auth/google-signin-failed'
      );
    }
  }

  private async createNewGoogleUser(userData: any) {
    let username = this.generateUsername(userData.displayName);
    let attempts = 0;
    const maxAttempts = 5;

    while (attempts < maxAttempts) {
      if (!await this.isUsernameTaken(username)) {
        break;
      }
      username = this.generateUsername(userData.displayName);
      attempts++;
    }

    if (attempts === maxAttempts) {
      throw new AuthError(
        'Unable to generate unique username',
        'auth/username-generation-failed'
      );
    }

    const batch = writeBatch(db);

    // Add debug logging
    console.log('Creating new Google user:', {
      uid: userData.uid,
      username,
      email: userData.email
    });

    batch.set(doc(db, this.USERNAMES_COLLECTION, username.toLowerCase()), {
      uid: userData.uid,
      username,
      normalizedUsername: username.toLowerCase(),
      createdAt: new Date()
    });

    batch.set(doc(db, this.USERS_COLLECTION, userData.uid), {
      username,
      normalizedUsername: username.toLowerCase(),
      email: userData.email,
      displayName: userData.displayName,
      role: UserRole.USER,
      plan: SubscriptionPlan.FREE,
      createdAt: new Date(),
      lastLogin: new Date(),
      authProvider: 'google',
      emailVerified: userData.emailVerified
    });

    try {
      await batch.commit();
      console.log('Successfully created new Google user');
      window.location.href = '/username';
    } catch (error) {
      console.error('Error creating new Google user:', error);
      throw error;
    }
  }

  private async updateExistingUser(userData: any) {
    try {
      await setDoc(
        doc(db, this.USERS_COLLECTION, userData.uid),
        {
          lastLogin: new Date(),
          displayName: userData.displayName,
          emailVerified: userData.emailVerified
        },
        { merge: true }
      );
      window.location.href = '/game';
    } catch (error) {
      console.error('Error updating existing Google user:', error);
      throw error;
    }
  }

  async signUp(
    email: string,
    password: string,
    username: string
  ): Promise<UserCredential> {
    try {
      if (!this.validateUsername(username)) {
        throw new AuthError(
          'Invalid username format',
          'auth/invalid-username'
        );
      }

      const normalized = username.toLowerCase();
      const usernameTaken = await this.isUsernameTaken(normalized);

      if (usernameTaken) {
        throw new AuthError(
          'Username already taken',
          'auth/username-exists'
        );
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );

      try {
        const batch = writeBatch(db);

        // Create username document
        batch.set(doc(db, this.USERNAMES_COLLECTION, normalized), {
          uid: userCredential.user.uid,
          username,
          normalizedUsername: normalized,
          createdAt: new Date()
        });

        // Create user document
        batch.set(doc(db, this.USERS_COLLECTION, userCredential.user.uid), {
          username,
          normalizedUsername: normalized,
          email,
          role: UserRole.USER,
          plan: SubscriptionPlan.FREE,
          createdAt: new Date(),
          lastLogin: new Date(),
          authProvider: 'email'
        });

        await batch.commit();
        return userCredential;
      } catch (error) {
        // Cleanup if user document creation fails
        await userCredential.user.delete();
        throw error;
      }
    } catch (error: any) {
      if (auth.currentUser) {
        await auth.currentUser.delete();
      }
      throw new AuthError(
        error.message || 'Failed to create account',
        error.code || 'auth/unknown-error'
      );
    }
  }

  async signIn(email: string, password: string): Promise<UserCredential> {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);

      // Update last login timestamp
      await setDoc(
        doc(db, this.USERS_COLLECTION, userCredential.user.uid),
        { lastLogin: new Date() },
        { merge: true }
      );

      return userCredential;
    } catch (error: any) {
      throw new AuthError(
        this.getReadableErrorMessage(error),
        error.code
      );
    }
  }

  async signOut(): Promise<void> {
    try {
      await signOut(auth);
      localStorage.removeItem('user');
    } catch (error: any) {
      throw new AuthError(
        'Failed to sign out',
        error.code
      );
    }
  }

  async resetPassword(email: string): Promise<void> {
    try {
      await sendPasswordResetEmail(auth, email);
    } catch (error: any) {
      throw new AuthError(
        this.getReadableErrorMessage(error),
        error.code
      );
    }
  }

  async updateUserData(
    userId: string,
    data: Partial<UserData>
  ): Promise<void> {
    try {
      const userRef = doc(db, this.USERS_COLLECTION, userId);

      if (data.username) {
        if (!this.validateUsername(data.username)) {
          throw new AuthError(
            'Invalid username format',
            'auth/invalid-username-format'
          );
        }

        const newNormalizedUsername = data.username.toLowerCase();

        // Fetch current user data
        const userDoc = await getDoc(userRef);
        if (!userDoc.exists()) {
          throw new AuthError('User not found', 'auth/user-not-found');
        }

        const userData = userDoc.data() as UserData;
        const oldNormalizedUsername = userData.normalizedUsername;

        // Check if new username is available
        if (newNormalizedUsername !== oldNormalizedUsername) {
          const usernameTaken = await this.isUsernameTaken(newNormalizedUsername);
          if (usernameTaken) {
            throw new AuthError(
              'Username already taken',
              'auth/username-exists'
            );
          }
        }

        // Update username atomically
        await runTransaction(db, async (transaction) => {
          // Delete old username document
          if (oldNormalizedUsername && oldNormalizedUsername !== newNormalizedUsername) {
            const oldUsernameRef = doc(db, this.USERNAMES_COLLECTION, oldNormalizedUsername);
            transaction.delete(oldUsernameRef);
          }

          // Create new username document
          const newUsernameRef = doc(db, this.USERNAMES_COLLECTION, newNormalizedUsername);
          transaction.set(newUsernameRef, {
            uid: userId,
            username: data.username,
            normalizedUsername: newNormalizedUsername,
            updatedAt: new Date()
          });

          // Update user document
          transaction.update(userRef, {
            ...data,
            normalizedUsername: newNormalizedUsername,
            lastUpdated: new Date()
          });
        });
      } else {
        // Simple update without username change
        await setDoc(userRef, {
          ...data,
          lastUpdated: new Date()
        }, { merge: true });
      }
    } catch (error: any) {
      throw new AuthError(
        error.message || 'Failed to update user data',
        error.code || 'auth/update-failed'
      );
    }
  }

  async getUserData(userId: string): Promise<UserData | null> {
    try {
      const userDoc = await getDoc(doc(db, this.USERS_COLLECTION, userId));
      return userDoc.exists() ? userDoc.data() as UserData : null;
    } catch (error: any) {
      throw new AuthError(
        'Failed to fetch user data',
        'auth/fetch-failed'
      );
    }
  }

  async updateUserPlan(
    userId: string,
    plan: SubscriptionPlan
  ): Promise<void> {
    try {
      await setDoc(
        doc(db, this.USERS_COLLECTION, userId),
        {
          plan,
          lastUpdated: new Date()
        },
        { merge: true }
      );
    } catch (error: any) {
      throw new AuthError(
        'Failed to update subscription plan',
        'auth/update-plan-failed'
      );
    }
  }

  async updateUserRole(
    userId: string,
    role: UserRole
  ): Promise<void> {
    try {
      await setDoc(
        doc(db, this.USERS_COLLECTION, userId),
        {
          role,
          lastUpdated: new Date()
        },
        { merge: true }
      );
    } catch (error: any) {
      throw new AuthError(
        'Failed to update user role',
        'auth/update-role-failed'
      );
    }
  }

  public getReadableErrorMessage(error: { code?: string, message: string }): string {
    switch (error.code) {
      case 'auth/email-already-in-use':
        return 'This email is already registered. Please sign in or use a different email.';
      case 'auth/invalid-email':
        return 'Please enter a valid email address.';
      case 'auth/operation-not-allowed':
        return 'Email/password sign up is not enabled. Please contact support.';
      case 'auth/weak-password':
        return 'Please choose a stronger password. It should be at least 6 characters long.';
      case 'auth/user-disabled':
        return 'This account has been disabled. Please contact support.';
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        return 'Invalid email or password.';
      case 'auth/too-many-requests':
        return 'Too many failed attempts. Please try again later.';
      case 'auth/popup-closed-by-user':
        return 'Sign-in popup was closed. Please try again.';
      case 'auth/cancelled-popup-request':
        return 'The sign-in process was cancelled. Please try again.';
      case 'auth/popup-blocked':
        return 'Sign-in popup was blocked by your browser. Please allow popups and try again.';
      case 'auth/account-exists-with-different-credential':
        return 'An account already exists with this email but with different sign-in credentials. Please sign in using the original method.';
      default:
        return error.message || 'An unexpected error occurred. Please try again.';
    }
  }

  async getCurrentUser(): Promise<User | null> {
    return new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        unsubscribe();
        resolve(user);
      });
    });
  }

  onAuthStateChanged(callback: (user: User | null) => void): () => void {
    return auth.onAuthStateChanged(callback);
  }
}

export const authService = new AuthService();