import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  User, Lock, Mail, Bell, Shield,
  Save, AlertCircle, Check,
  Loader2, X
} from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Header, Footer } from '@/components/ui/FootHead';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import { auth } from '@/lib/firebase';
import {
  updateProfile,
  updateEmail,
  updatePassword,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth';
import { authService } from '@/services/auth.service';
import { UserData } from '@/types';
import { AuthError } from '@/lib/errors';

// Type for preferences
interface UserPreferences {
  emailNotifications: boolean;
  pushNotifications: boolean;
  twoFactorAuth: boolean;
}

// Local storage key
const PREFERENCES_STORAGE_KEY = 'user-preferences';

// Function to load preferences from localStorage
const loadPreferences = (): UserPreferences => {
  const stored = localStorage.getItem(PREFERENCES_STORAGE_KEY);
  if (stored) {
    try {
      return JSON.parse(stored);
    } catch {
      // If parsing fails, return defaults
      return {
        emailNotifications: true,
        pushNotifications: true,
        twoFactorAuth: false
      };
    }
  }
  return {
    emailNotifications: true,
    pushNotifications: true,
    twoFactorAuth: false
  };
};

// Function to save preferences to localStorage
const savePreferences = (prefs: UserPreferences) => {
  localStorage.setItem(PREFERENCES_STORAGE_KEY, JSON.stringify(prefs));
};


const AccountPage = () => {
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const [preferences, setPreferences] = useState<UserPreferences>(() => loadPreferences());

  const [formData, setFormData] = useState({
    username: '',
    email: '',
    language: 'English',
    timezone: 'UTC-5',
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  // Add this effect to save preferences when they change
  useEffect(() => {
    savePreferences(preferences);
  }, [preferences]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (!storedUser) {
          navigate('/auth');
          return;
        }

        const user = JSON.parse(storedUser);
        const data = await authService.getUserData(user.uid);
        if (data) {
          setUserData(data);
          setFormData(prev => ({
            ...prev,
            username: data.username,
            email: data.email,
            language: 'English', // Set default or get from localStorage
            timezone: 'UTC-5'  // Set default or get from localStorage
          }));
        }
        console.log('User data loaded:', data);
      } catch (error) {
        console.error('Error loading user data:', error);
        setErrorMessage('Failed to load user data. Please try again.');
        navigate('/auth');
      }
    };

    loadUserData();
  }, [navigate]);

  // Modify your handleSave function to only update critical data
  const handleSave = async () => {
    setIsSaving(true);
    setErrorMessage(null);

    try {
      if (!auth.currentUser) throw new Error('No user logged in');

      // Update display name if changed
      if (formData.username !== userData?.username) {
        await updateProfile(auth.currentUser, {
          displayName: formData.username
        });
      }

      // Update email if changed
      if (formData.email !== userData?.email) {
        // Prompt for password using window.prompt
        const password = window.prompt('Please enter your password to change email:');
        if (!password) {
          throw new Error('Password is required to change email');
        }

        // Re-authenticate user before email change
        const credential = EmailAuthProvider.credential(
          auth.currentUser.email!,
          password
        );
        await reauthenticateWithCredential(auth.currentUser, credential);
        await updateEmail(auth.currentUser, formData.email);
      }

      // Update user data in Firestore - only critical data
      await authService.updateUserData(auth.currentUser.uid, {
        username: formData.username,
        email: formData.email,
        lastUpdated: new Date()
      });

      setShowSuccessDialog(true);
      setTimeout(() => setShowSuccessDialog(false), 2000);
    } catch (error: any) {
      const authError = error as AuthError;
      setErrorMessage(authService.getReadableErrorMessage(authError));
    } finally {
      setIsSaving(false);
      setIsEditing(false);
    }
  };

  const handlePasswordChange = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSaving(true);
    setErrorMessage(null);

    try {
      if (!auth.currentUser) throw new Error('No user logged in');

      // Re-authenticate user before password change
      const credential = EmailAuthProvider.credential(
        auth.currentUser.email!,
        formData.currentPassword
      );
      await reauthenticateWithCredential(auth.currentUser, credential);

      // Update password
      await updatePassword(auth.currentUser, formData.newPassword);

      setShowChangePassword(false);
      setShowSuccessDialog(true);
      setTimeout(() => setShowSuccessDialog(false), 2000);
    } catch (error: any) {
      const authError = error as AuthError;
      setErrorMessage(authService.getReadableErrorMessage(authError));
    } finally {
      setIsSaving(false);
    }
  };

  if (!userData) {
    return (
      <div className="min-h-screen bg-[#0A0A1E] flex items-center justify-center">
        <motion.div
          animate={{ rotate: 360 }}
          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
          className="w-8 h-8 border-2 border-purple-500 border-t-transparent rounded-full"
        />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#0A0A1E] overflow-hidden">
      {/* Background animation */}
      <div className="fixed inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff10_1px,transparent_1px),linear-gradient(to_bottom,#ffffff10_1px,transparent_1px)] bg-[size:14px_24px] opacity-20" />
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-purple-900/30 via-transparent to-blue-900/30"
          animate={{
            background: [
              "linear-gradient(to bottom right, rgba(88,28,135,0.3), transparent, rgba(29,78,216,0.3))",
              "linear-gradient(to bottom right, rgba(29,78,216,0.3), transparent, rgba(88,28,135,0.3))"
            ]
          }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
      </div>

      <Header />

      <main className="relative pt-32 pb-20 px-6">
        <div className="max-w-4xl mx-auto">
          {/* Error Message */}
          <AnimatePresence>
            {errorMessage && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                className="mb-4 p-4 rounded-xl bg-red-500/10 border border-red-500/20 text-red-400 flex items-center gap-2"
              >
                <AlertCircle className="w-5 h-5" />
                {errorMessage}
              </motion.div>
            )}
          </AnimatePresence>

          {/* Profile Header */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="mb-8 flex items-center justify-between"
          >
            <div className="flex items-center gap-6">
              <div className="relative group">
              </div>
              <div>
                <h1 className="text-3xl font-bold text-white mb-1">{formData.username}</h1>
                <p className="text-gray-400">Member since {(userData.createdAt instanceof Date ? userData.createdAt : new Date()).toLocaleDateString('en-GB', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric'
                })}</p>
              </div>
            </div>

            <Button
              onClick={() => { auth.signOut(); navigate('/'); localStorage.removeItem('user'); }}
              className="gap-1"
            >
              Sign Out
            </Button>

            <Button
              onClick={() => setIsEditing(!isEditing)}
              variant={isEditing ? "destructive" : "default"}
              className="gap-2"
            >
              {isEditing ? (
                <>
                  <X className="w-4 h-4" />
                  Cancel
                </>
              ) : (
                <>
                  <User className="w-4 h-4" />
                  Edit Profile
                </>
              )}
            </Button>
          </motion.div>

          {/* Settings Sections */}
          <div className="space-y-6">
            {/* Profile Information */}
            <motion.section
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="rounded-xl border border-white/10 bg-white/5 backdrop-blur-xl overflow-hidden"
            >
              <div className="p-6">
                <h2 className="text-xl font-semibold text-white mb-6">Profile Information</h2>
                <div className="space-y-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="space-y-2">
                      <label className="text-sm text-gray-400">Username</label>
                      <Input
                        value={formData.username}
                        onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                        disabled={!isEditing}
                        className="bg-white/5 text-white"
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="text-sm text-gray-400">Email</label>
                      <Input
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        disabled={!isEditing}
                        className="bg-white/5 text-white"
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="text-sm text-gray-400">Language</label>
                      <Input
                        value={formData.language}
                        onChange={(e) => setFormData({ ...formData, language: e.target.value })}
                        disabled={!isEditing}
                        className="bg-white/5 text-white"
                      />
                    </div>
                    <div className="space-y-2">
                      <label className="text-sm text-gray-400">Timezone</label>
                      <Input
                        value={formData.timezone}
                        onChange={(e) => setFormData({ ...formData, timezone: e.target.value })}
                        disabled={!isEditing}
                        className="bg-white/5 text-white"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </motion.section>

            {/* Security Settings */}
            <motion.section
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="rounded-xl border border-white/10 bg-white/5 backdrop-blur-xl overflow-hidden"
            >
              <div className="p-6">
                <h2 className="text-xl font-semibold text-white mb-6">Security Settings</h2>
                <div className="space-y-4">
                  <div className="flex items-center justify-between py-3 border-b border-white/10">
                    <div className="flex items-center gap-3">
                      <div className="p-2 rounded-lg bg-purple-500/10">
                        <Lock className="w-5 h-5 text-purple-400" />
                      </div>
                      <div>
                        <h3 className="text-white font-medium">Password</h3>
                        <p className="text-gray-400 text-sm">Change your password</p>
                      </div>
                    </div>
                    <Button
                      onClick={() => setShowChangePassword(true)}
                      variant="outline"
                      className="gap-2"
                    >
                      <Shield className="w-4 h-4" />
                      Change Password
                    </Button>
                  </div>

                  <div className="flex items-center justify-between py-3">
                    <div className="flex items-center gap-3">
                      <div className="p-2 rounded-lg bg-blue-500/10">
                        <Shield className="w-5 h-5 text-blue-400" />
                      </div>
                      <div>
                        <h3 className="text-white font-medium">Two-Factor Authentication</h3>
                        <p className="text-gray-400 text-sm">Add an extra layer of security</p>
                      </div>
                    </div>
                    <Switch
                      checked={preferences.twoFactorAuth}
                      onCheckedChange={(checked) => setPreferences({ ...preferences, twoFactorAuth: checked })}
                    />
                  </div>
                </div>
              </div>
            </motion.section>

            {/* Notification Preferences */}
            <motion.section
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="rounded-xl border border-white/10 bg-white/5 backdrop-blur-xl overflow-hidden"
            >
              <div className="p-6">
                <h2 className="text-xl font-semibold text-white mb-6">Notification Preferences</h2>
                <div className="space-y-4">
                  <div className="flex items-center justify-between py-3 border-b border-white/10">
                    <div className="flex items-center gap-3">
                      <div className="p-2 rounded-lg bg-pink-500/10">
                        <Mail className="w-5 h-5 text-pink-400" />
                      </div>
                      <div>
                        <h3 className="text-white font-medium">Email Notifications</h3>
                        <p className="text-gray-400 text-sm">Receive email updates</p>
                      </div>
                    </div>
                    <Switch
                      checked={preferences.emailNotifications}
                      onCheckedChange={(checked) => setPreferences({ ...preferences, emailNotifications: checked })}
                    />
                  </div>

                  <div className="flex items-center justify-between py-3">
                    <div className="flex items-center gap-3">
                      <div className="p-2 rounded-lg bg-orange-500/10">
                        <Bell className="w-5 h-5 text-orange-400" />
                      </div>
                      <div>
                        <h3 className="text-white font-medium">Push Notifications</h3>
                        <p className="text-gray-400 text-sm">Receive push notifications</p>
                      </div>
                    </div>
                    <Switch
                      checked={preferences.pushNotifications}
                      onCheckedChange={(checked) => setPreferences({ ...preferences, pushNotifications: checked })}
                    />
                  </div>
                </div>
              </div>
            </motion.section>

            {/* Save Button */}
            {isEditing && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="flex justify-end"
              >
                <Button
                  onClick={handleSave}
                  disabled={isSaving}
                  className="gap-2"
                >
                  {isSaving ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      Saving...
                    </>
                  ) : (
                    <>
                      <Save className="w-4 h-4" />
                      Save Changes
                    </>
                  )}
                </Button>
              </motion.div>
            )}
          </div>
        </div>
      </main>

      {/* Password Change Dialog */}
      <Dialog isOpen={showChangePassword} onClose={() => setShowChangePassword(false)}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Change Password</DialogTitle>
          </DialogHeader>
          <form onSubmit={handlePasswordChange} className="space-y-4 mt-4">
            <div className="space-y-2">
              <label className="text-sm text-gray-400">Current Password</label>
              <Input
                type="password"
                value={formData.currentPassword}
                onChange={(e) => setFormData({ ...formData, currentPassword: e.target.value })}
                className="bg-white/5"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm text-gray-400">New Password</label>
              <Input
                type="password"
                value={formData.newPassword}
                onChange={(e) => setFormData({ ...formData, newPassword: e.target.value })}
                className="bg-white/5"
              />
            </div>
            <div className="space-y-2">
              <label className="text-sm text-gray-400">Confirm New Password</label>
              <Input
                type="password"
                value={formData.confirmPassword}
                onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                className="bg-white/5"
              />
            </div>
            <div className="flex justify-end gap-2">
              <Button
                type="button"
                variant="outline"
                onClick={() => setShowChangePassword(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={isSaving}
                className="gap-2"
              >
                {isSaving ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    Updating...
                  </>
                ) : (
                  <>
                    <Save className="w-4 h-4" />
                    Update Password
                  </>
                )}
              </Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      {/* Success Dialog */}
      <Dialog isOpen={showSuccessDialog} onClose={() => setShowSuccessDialog(false)}>
        <DialogContent>
          <div className="flex flex-col items-center justify-center p-6">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="w-12 h-12 rounded-full bg-green-500 flex items-center justify-center mb-4"
            >
              <Check className="w-6 h-6 text-white" />
            </motion.div>
            <h3 className="text-xl font-semibold text-white mb-2">Success!</h3>
            <p className="text-gray-400 text-center">Your changes have been saved successfully.</p>
          </div>
        </DialogContent>
      </Dialog>

      <Footer />
    </div>
  );
};

export default AccountPage;