import React from 'react';

interface ScrollableContainerProps {
  children: React.ReactNode;
  className?: string;
}

const ScrollableContainer: React.FC<ScrollableContainerProps> = ({ children, className = '' }) => {
  return (
    <div className={`
      [&::-webkit-scrollbar]:w-2 
      [&::-webkit-scrollbar-track]:bg-gray-800/50
      [&::-webkit-scrollbar-thumb]:bg-purple-900
      [&::-webkit-scrollbar-thumb]:rounded-full
      [&::-webkit-scrollbar-thumb]:border-2
      [&::-webkit-scrollbar-thumb]:border-transparent
      [&::-webkit-scrollbar-thumb]:bg-clip-padding
      [&::-webkit-scrollbar-thumb]:hover:bg-purple-700
      hover:[&::-webkit-scrollbar-thumb]:bg-purple-800
      ${className}
    `}>
      {children}
    </div>
  );
};

export default ScrollableContainer;