import { db } from '../lib/firebase';
import {
  Airline,
  Aircraft,
  Route,
  Flight,
  AirlineAlliance,
  FlightStatus
} from '../types';
import { ApiError } from '../lib/errors';
import {
  doc,
  setDoc,
  getDoc,
  getDocs,
  query,
  where,
  collection,
  runTransaction,
  writeBatch
} from 'firebase/firestore';

class AirlineService {
  private readonly COLLECTION = 'airlines';

  private validateAirlineCode(code: string): boolean {
    return /^[A-Z]{3}$/.test(code);
  }

  private validateCallsign(callsign: string): boolean {
    return /^[A-Z]{2}$/.test(callsign);
  }

  private async isCodeTaken(code: string, excludeId?: string): Promise<boolean> {
    const airlineQuery = query(
      collection(db, this.COLLECTION),
      where('code', '==', code)
    );
    const snapshot = await getDocs(airlineQuery);

    if (excludeId) {
      return snapshot.docs.some(doc => doc.id !== excludeId);
    }
    return !snapshot.empty;
  }

  private async isCallsignTaken(callsign: string, excludeId?: string): Promise<boolean> {
    const airlineQuery = query(
      collection(db, this.COLLECTION),
      where('callsign', '==', callsign)
    );
    const snapshot = await getDocs(airlineQuery);

    if (excludeId) {
      return snapshot.docs.some(doc => doc.id !== excludeId);
    }
    return !snapshot.empty;
  }

  private generateAirlineId(): string {
    return `AL${Date.now()}${Math.floor(Math.random() * 1000).toString().padStart(3, '0')}`;
  }

  async create(data: Omit<Airline, 'id' | 'lastUpdated'>): Promise<string> {
    try {
      if (!this.validateAirlineCode(data.code)) {
        throw new ApiError('Invalid airline code format. Must be 3 uppercase letters.');
      }

      if (!this.validateCallsign(data.callsign)) {
        throw new ApiError('Invalid callsign format. Must be 2 uppercase letters.');
      }

      const [codeTaken, callsignTaken] = await Promise.all([
        this.isCodeTaken(data.code),
        this.isCallsignTaken(data.callsign)
      ]);

      if (codeTaken) {
        throw new ApiError('Airline code is already taken.');
      }

      if (callsignTaken) {
        throw new ApiError('Airline callsign is already taken.');
      }

      const airlineId = this.generateAirlineId();
      const airline: Airline = {
        ...data,
        id: airlineId,
        lastUpdated: new Date()
      };

      await setDoc(doc(db, this.COLLECTION, airlineId), airline);
      return airlineId;
    } catch (error: any) {
      throw new ApiError(
        error.message || 'Failed to create airline!',
        error.code
      );
    }
  }

  async getById(id: string): Promise<Airline | null> {
    try {
      const airlineDoc = await getDoc(doc(db, this.COLLECTION, id));
      return airlineDoc.exists() ? airlineDoc.data() as Airline : null;
    } catch (error: any) {
      throw new ApiError('Failed to fetch airline data');
    }
  }

  async update(id: string, data: Partial<Airline>): Promise<void> {
    try {
      const airlineRef = doc(db, this.COLLECTION, id);

      await runTransaction(db, async (transaction) => {
        const airlineDoc = await transaction.get(airlineRef);

        if (!airlineDoc.exists()) {
          throw new ApiError('Airline not found');
        }

        const currentAirline = airlineDoc.data() as Airline;

        if (data.code && data.code !== currentAirline.code) {
          if (!this.validateAirlineCode(data.code)) {
            throw new ApiError('Invalid airline code format');
          }
          if (await this.isCodeTaken(data.code, id)) {
            throw new ApiError('Airline code is already taken');
          }
        }

        if (data.callsign && data.callsign !== currentAirline.callsign) {
          if (!this.validateCallsign(data.callsign)) {
            throw new ApiError('Invalid callsign format');
          }
          if (await this.isCallsignTaken(data.callsign, id)) {
            throw new ApiError('Airline callsign is already taken');
          }
        }

        transaction.update(airlineRef, {
          ...data,
          lastUpdated: new Date()
        });
      });
    } catch (error: any) {
      throw new ApiError(
        error.message || 'Failed to update airline',
        error.code
      );
    }
  }

  async delete(id: string): Promise<void> {
    try {
      const batch = writeBatch(db);

      // Delete the airline document
      batch.delete(doc(db, this.COLLECTION, id));

      // Delete related collections
      const collections = ['routes', 'aircraft', 'flights'];
      const deletePromises = collections.map(async (collectionName) => {
        const querySnapshot = await getDocs(
          query(collection(db, collectionName), where('airlineId', '==', id))
        );

        querySnapshot.forEach((doc) => {
          batch.delete(doc.ref);
        });
      });

      await Promise.all(deletePromises);
      await batch.commit();
    } catch (error: any) {
      throw new ApiError('Failed to delete airline');
    }
  }

  async addAircraft(airlineId: string, aircraftData: Omit<Aircraft, 'id'>): Promise<string> {
    try {
      const airlineRef = doc(db, this.COLLECTION, airlineId);
      const aircraftId = `AC${Date.now()}`;

      await runTransaction(db, async (transaction) => {
        const airlineDoc = await transaction.get(airlineRef);
        if (!airlineDoc.exists()) {
          throw new ApiError('Airline not found');
        }

        const airline = airlineDoc.data() as Airline;

        transaction.update(airlineRef, {
          'stats.fleetSize': airline.stats.fleetSize + 1,
          lastUpdated: new Date()
        });

        transaction.set(doc(db, 'aircraft', aircraftId), {
          ...aircraftData,
          id: aircraftId,
          airlineId
        });
      });

      return aircraftId;
    } catch (error: any) {
      throw new ApiError('Failed to add aircraft to fleet');
    }
  }

  async addRoute(airlineId: string, routeData: Omit<Route, 'id'>): Promise<string> {
    try {
      const routeId = `RT${Date.now()}`;
      const airlineRef = doc(db, this.COLLECTION, airlineId);

      await runTransaction(db, async (transaction) => {
        const airlineDoc = await transaction.get(airlineRef);
        if (!airlineDoc.exists()) {
          throw new ApiError('Airline not found');
        }

        const airline = airlineDoc.data() as Airline;

        transaction.update(airlineRef, {
          'stats.destinations': airline.stats.destinations + 1,
          lastUpdated: new Date()
        });

        transaction.set(doc(db, 'routes', routeId), {
          ...routeData,
          id: routeId,
          airlineId
        });
      });

      return routeId;
    } catch (error: any) {
      throw new ApiError('Failed to add route');
    }
  }

  async scheduleFlight(airlineId: string, flightData: Omit<Flight, 'id'>): Promise<string> {
    try {
      const flightId = `FL${Date.now()}`;
      const airlineRef = doc(db, this.COLLECTION, airlineId);

      await runTransaction(db, async (transaction) => {
        const airlineDoc = await transaction.get(airlineRef);
        if (!airlineDoc.exists()) {
          throw new ApiError('Airline not found');
        }

        const airline = airlineDoc.data() as Airline;

        transaction.update(airlineRef, {
          'stats.dailyFlights': airline.stats.dailyFlights + 1,
          lastUpdated: new Date()
        });

        transaction.set(doc(db, 'flights', flightId), {
          ...flightData,
          id: flightId,
          airlineId
        });
      });

      return flightId;
    } catch (error: any) {
      throw new ApiError('Failed to schedule flight');
    }
  }

  async getByOwner(ownerId: string): Promise<Airline[]> {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, this.COLLECTION), where('ownerId', '==', ownerId))
      );
      return querySnapshot.docs.map(doc => doc.data() as Airline);
    } catch (error: any) {
      throw new ApiError('Failed to fetch owner airlines');
    }
  }

  async getByAlliance(alliance: AirlineAlliance): Promise<Airline[]> {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, this.COLLECTION), where('alliance', '==', alliance))
      );
      return querySnapshot.docs.map(doc => doc.data() as Airline);
    } catch (error: any) {
      throw new ApiError('Failed to fetch alliance airlines');
    }
  }

  async getFleet(airlineId: string): Promise<Aircraft[]> {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, 'aircraft'), where('airlineId', '==', airlineId))
      );
      return querySnapshot.docs.map(doc => doc.data() as Aircraft);
    } catch (error: any) {
      throw new ApiError('Failed to fetch airline fleet');
    }
  }

  async getRoutes(airlineId: string): Promise<Route[]> {
    try {
      const querySnapshot = await getDocs(
        query(collection(db, 'routes'), where('airlineId', '==', airlineId))
      );
      return querySnapshot.docs.map(doc => doc.data() as Route);
    } catch (error: any) {
      throw new ApiError('Failed to fetch airline routes');
    }
  }

  async getFlights(airlineId: string, status?: FlightStatus): Promise<Flight[]> {
    try {
      let flightQuery = query(
        collection(db, 'flights'),
        where('airlineId', '==', airlineId)
      );

      if (status) {
        flightQuery = query(flightQuery, where('status', '==', status));
      }

      const querySnapshot = await getDocs(flightQuery);
      return querySnapshot.docs.map(doc => doc.data() as Flight);
    } catch (error: any) {
      throw new ApiError('Failed to fetch airline flights');
    }
  }
}

export const airlineService = new AirlineService();