import React, { useState, useMemo, useCallback } from 'react';
import Map, {
  Source,
  Layer,
  ViewState,
  MapLayerMouseEvent
} from 'react-map-gl';
import { motion } from 'framer-motion';
import {
  Eye,
  EyeOff,
  Layers,
  ZoomIn,
  ZoomOut
} from 'lucide-react';

// Types
interface Airport {
  id: number;
  name: string;
  position: [number, number];
  isHub: boolean;
  traffic?: number;
}

interface Route {
  id: number;
  from: string;
  to: string;
  coordinates: [number, number][];
  active: boolean;
  frequency?: number;
}

interface LayerVisibility {
  airports: boolean;
  routes: boolean;
  weather: boolean;
}

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

interface MapControlsProps {
  showLayers: LayerVisibility;
  setShowLayers: React.Dispatch<React.SetStateAction<LayerVisibility>>;
  onZoomIn: () => void;
  onZoomOut: () => void;
}

const MapControls: React.FC<MapControlsProps> = ({
  showLayers,
  setShowLayers,
  onZoomIn,
  onZoomOut
}) => (
  <div className="absolute top-4 right-4 flex flex-col gap-2">
    <motion.div
      className="p-3 rounded-2xl bg-black/80 backdrop-blur-xl border border-white/10 shadow-lg shadow-purple-500/10"
      whileHover={{ scale: 1.02 }}
    >
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 text-white/80 text-sm border-b border-white/10 pb-2 mb-1">
          <Layers className="w-4 h-4" />
          <span>Layers</span>
        </div>
        {Object.entries(showLayers).map(([layer, visible]) => (
          <button
            key={layer}
            onClick={() => setShowLayers(prev => ({ ...prev, [layer]: !visible }))}
            className="flex items-center gap-2 text-white/70 text-sm px-3 py-1.5 rounded-lg hover:bg-white/10 transition-colors"
          >
            {visible ? <Eye className="w-4 h-4" /> : <EyeOff className="w-4 h-4" />}
            {layer.charAt(0).toUpperCase() + layer.slice(1)}
          </button>
        ))}
      </div>
    </motion.div>

    <div className="flex flex-col gap-1">
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={onZoomIn}
        className="p-2 rounded-lg bg-black/80 backdrop-blur-xl border border-white/10 text-white/80 hover:text-white transition-colors shadow-lg shadow-purple-500/10"
      >
        <ZoomIn className="w-5 h-5" />
      </motion.button>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        onClick={onZoomOut}
        className="p-2 rounded-lg bg-black/80 backdrop-blur-xl border border-white/10 text-white/80 hover:text-white transition-colors shadow-lg shadow-purple-500/10"
      >
        <ZoomOut className="w-5 h-5" />
      </motion.button>
    </div>
  </div>
);

const CustomMapbox: React.FC = () => {
  const [viewState, setViewState] = useState<ViewState>({
    longitude: -95,
    latitude: 37,
    zoom: 3,
    pitch: 0,
    bearing: 0,
    padding: { top: 0, bottom: 0, left: 0, right: 0 }
  });

  const [selectedAirport, setSelectedAirport] = useState<Airport | null>(null);
  const [showLayers, setShowLayers] = useState<LayerVisibility>({
    airports: true,
    routes: true,
    weather: false
  });

  const airports = useMemo<Airport[]>(() => [
    { id: 1, name: 'JFK', position: [-73.7781, 40.6413], isHub: true, traffic: 1200 },
    { id: 2, name: 'LAX', position: [-118.4085, 33.9416], isHub: true, traffic: 1500 },
    { id: 3, name: 'ORD', position: [-87.9073, 41.9742], isHub: true, traffic: 1300 },
    { id: 4, name: 'MIA', position: [-80.2870, 25.7951], isHub: false, traffic: 800 },
    { id: 5, name: 'SEA', position: [-122.3088, 47.4502], isHub: false, traffic: 600 },
  ], []);

  const routes = useMemo<Route[]>(() => [
    {
      id: 1,
      from: 'JFK',
      to: 'LAX',
      coordinates: [[-73.7781, 40.6413], [-118.4085, 33.9416]],
      active: true,
      frequency: 12
    },
    {
      id: 2,
      from: 'ORD',
      to: 'MIA',
      coordinates: [[-87.9073, 41.9742], [-80.2870, 25.7951]],
      active: true,
      frequency: 8
    },
  ], []);

  const handleZoomIn = useCallback(() => {
    setViewState(prev => ({
      ...prev,
      zoom: Math.min(prev.zoom + 1, 20)
    }));
  }, []);

  const handleZoomOut = useCallback(() => {
    setViewState(prev => ({
      ...prev,
      zoom: Math.max(prev.zoom - 1, 1)
    }));
  }, []);

  const handleMapClick = useCallback((event: MapLayerMouseEvent) => {
    // Clear selection when clicking on the map background
    setSelectedAirport(null);
  }, []);

  return (
    <div className="relative w-full h-full">
      <Map
        {...viewState}
        onMove={evt => setViewState(evt.viewState)}
        onClick={handleMapClick}
        mapStyle="mapbox://styles/mapbox/dark-v11"
        mapboxAccessToken={MAPBOX_TOKEN}
        attributionControl={false}
        interactiveLayerIds={['route-lines']}
      >
        <MapControls
          showLayers={showLayers}
          setShowLayers={setShowLayers}
          onZoomIn={handleZoomIn}
          onZoomOut={handleZoomOut}
        />

        {showLayers.routes && (
          <Source
            id="routes"
            type="geojson"
            data={{
              type: 'FeatureCollection',
              features: routes.map(route => ({
                type: 'Feature',
                geometry: {
                  type: 'LineString',
                  coordinates: route.coordinates
                },
                properties: {
                  active: route.active,
                  frequency: route.frequency
                }
              }))
            }}
          >
            <Layer
              id="route-lines"
              type="line"
              paint={{
                'line-color': '#8B5CF6',
                'line-width': ['interpolate', ['linear'], ['get', 'frequency'],
                  5, 1,
                  20, 3
                ],
                'line-opacity': 0.6,
                'line-dasharray': [2, 2]
              }}
            />
          </Source>
        )}

        {showLayers.airports && (
          <Source
            id="airports"
            type="geojson"
            data={{
              type: 'FeatureCollection',
              features: airports.map(airport => ({
                type: 'Feature',
                geometry: {
                  type: 'Point',
                  coordinates: airport.position
                },
                properties: {
                  id: airport.id,
                  name: airport.name,
                  isHub: airport.isHub,
                  isSelected: selectedAirport?.id === airport.id
                }
              }))
            }}
          >
            <Layer
              id="airport-points"
              type="circle"
              paint={{
                'circle-radius': [
                  'case',
                  ['get', 'isSelected'], 12,
                  ['get', 'isHub'], 10,
                  8
                ],
                'circle-color': [
                  'case',
                  ['get', 'isHub'], '#8B5CF6',
                  '#3B82F6'
                ],
                'circle-opacity': 0.8,
                'circle-stroke-width': [
                  'case',
                  ['get', 'isSelected'], 2,
                  1
                ],
                'circle-stroke-color': 'white'
              }}
            />
            <Layer
              id="airport-labels"
              type="symbol"
              layout={{
                'text-field': ['get', 'name'],
                'text-offset': [0, 1.5],
                'text-anchor': 'top',
                'text-size': 12
              }}
              paint={{
                'text-color': 'white',
                'text-halo-color': 'rgba(0,0,0,0.8)',
                'text-halo-width': 1
              }}
            />
          </Source>
        )}
      </Map>
    </div>
  );
};

export default CustomMapbox;