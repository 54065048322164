import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { signInWithCredential, GoogleAuthProvider } from 'firebase/auth';
import { auth } from '../lib/firebase';
import { Loader2, AlertTriangle } from 'lucide-react';

interface TokenResponse {
    access_token: string;
    id_token: string;
    expires_in: number;
}

const exchangeCodeForTokens = async (code: string): Promise<TokenResponse> => {
    const tokenEndpoint = 'https://oauth2.googleapis.com/token';
    const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_SECRET;
    const redirectUri = `${window.location.origin}/__/auth/handler`;

    const params = new URLSearchParams({
        code,
        client_id: clientId!,
        client_secret: clientSecret!,
        redirect_uri: redirectUri,
        grant_type: 'authorization_code'
    });

    const response = await fetch(tokenEndpoint, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: params.toString()
    });

    if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error_description || 'Failed to exchange code for tokens');
    }

    return response.json();
};

const getReadableErrorMessage = (error: any): string => {
    const errorCode = error.code as string;
    const errorMap: Record<string, string> = {
        'auth/invalid-credential': 'The authentication credential is invalid. Please try signing in again.',
        'auth/operation-not-allowed': 'This sign-in method is not enabled. Please contact support.',
        'auth/popup-blocked': 'The sign-in popup was blocked by your browser. Please enable popups and try again.',
        'auth/popup-closed-by-user': 'The sign-in popup was closed before completion. Please try again.',
        'auth/timeout': 'The operation has timed out. Please try again.',
        'auth/network-request-failed': 'A network error occurred. Please check your connection and try again.'
    };

    return errorMap[errorCode] || error.message || 'An unexpected error occurred during authentication.';
};

export const AuthHandler: React.FC = () => {
    const [error, setError] = useState<string | null>(null);
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        let mounted = true;

        const handleAuthResult = async () => {
            try {
                const code = searchParams.get('code');

                if (!code) {
                    throw new Error('No authentication code received');
                }

                const tokens = await exchangeCodeForTokens(code);
                const credential = GoogleAuthProvider.credential(
                    tokens.id_token,
                    tokens.access_token
                );

                const result = await signInWithCredential(auth, credential);

                if (!mounted) return;

                if (window.opener) {
                    const message = {
                        type: 'AUTH_COMPLETE',
                        success: true,
                        user: {
                            uid: result.user.uid,
                            email: result.user.email,
                            displayName: result.user.displayName,
                            photoURL: result.user.photoURL,
                            providerId: result.user.providerId,
                            emailVerified: result.user.emailVerified
                        }
                    };

                    // Send message and wait before closing
                    window.opener.postMessage(message, window.location.origin);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    if (mounted) window.close();
                } else {
                    const returnUrl = localStorage.getItem('authReturnUrl') || '/game';
                    if (mounted) navigate(returnUrl);
                }
            } catch (error: any) {
                console.error('Auth Error:', error);
                if (!mounted) return;

                const errorMessage = getReadableErrorMessage(error);
                setError(errorMessage);

                if (window.opener) {
                    window.opener.postMessage({
                        type: 'AUTH_COMPLETE',
                        success: false,
                        error: errorMessage
                    }, window.location.origin);
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    if (mounted) window.close();
                }
            }
        };

        handleAuthResult();

        return () => {
            mounted = false;
        };
    }, [navigate, searchParams]);

    if (error) {
        return (
            <div className="min-h-screen flex items-center justify-center bg-[#0A0A1E] p-4">
                <div className="max-w-md w-full bg-white/5 backdrop-blur-xl border border-white/10 rounded-xl p-6 text-center">
                    <AlertTriangle className="w-12 h-12 text-red-400 mx-auto mb-4" />
                    <h2 className="text-xl font-bold text-white mb-2">Authentication Failed</h2>
                    <p className="text-red-400 mb-6">{error}</p>
                    <div className="flex justify-center gap-4">
                        <button
                            onClick={() => window.location.href = '/auth'}
                            className="px-4 py-2 bg-purple-500 text-white rounded-lg hover:bg-purple-600 transition-colors"
                        >
                            Try Again
                        </button>
                        {window.opener && (
                            <button
                                onClick={() => window.close()}
                                className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600 transition-colors"
                            >
                                Close Window
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="min-h-screen flex items-center justify-center bg-[#0A0A1E] p-4">
            <div className="text-center">
                <Loader2 className="w-12 h-12 text-purple-500 animate-spin mx-auto" />
                <h2 className="text-xl font-bold text-white mt-4 mb-2">
                    Completing Authentication
                </h2>
                <p className="text-gray-400">
                    Please wait while we secure your session...
                </p>
            </div>
        </div>
    );
};

export default AuthHandler;