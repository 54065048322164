import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  Plane, DollarSign, Users, Star,
  Building2, Gauge, TrendingUp,
  LucideIcon, Clock, ArrowRight,
  MoreHorizontal,
  Wallet, CalendarClock, ActivitySquare,
  AlertTriangle, CheckCircle2,
  Cloud,
  Wind,
  Fuel,
  ShieldCheck,
  HeartPulse,
  ArrowUpRight,
  Package,
  RefreshCcw
} from 'lucide-react';
import CustomMapbox from '../Components/GameMap';

// Types
interface Flight {
  id: string;
  flightNumber: string;
  status: string;
  origin: string;
  destination: string;
  eta: string;
  progress: number;
  departureTime: string;
  arrivalTime: string;
}

// Component for overview stats cards
const StatCard = ({ icon: Icon, title, value, trend, gradient }: {
  icon: LucideIcon;
  title: string;
  value: string;
  trend?: string;
  gradient: string
}) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="relative group"
  >
    <div className={`absolute inset-0 bg-gradient-to-br ${gradient} rounded-xl opacity-5 group-hover:opacity-10 transition-opacity blur-xl`} />
    <div className="relative rounded-xl bg-black/40 backdrop-blur-xl border border-white/10 hover:border-white/20 transition-colors">
      <div className="flex items-start p-4">
        <div className={`p-3 rounded-xl bg-gradient-to-br ${gradient}`}>
          <Icon className="w-5 h-5 text-white" />
        </div>
        <div className="ml-4 flex-1">
          <p className="text-gray-400 text-sm">{title}</p>
          <div className="mt-1 flex items-baseline gap-2">
            <h3 className="text-2xl font-bold text-white">{value}</h3>
            {trend && (
              <div className="flex items-center gap-1">
                <TrendingUp className="w-3 h-3 text-green-400" />
                <span className="text-green-400 text-xs">{trend}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  </motion.div>
);

// Component for active flights
const ActiveFlight = ({ flight }: { flight: Flight }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="group"
  >
    <div className="p-4 rounded-xl bg-black/40 backdrop-blur-xl border border-white/10 hover:border-purple-500/50 transition-all duration-300">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="p-2.5 rounded-xl bg-gradient-to-br from-purple-500/20 to-pink-500/20 group-hover:from-purple-500/30 group-hover:to-pink-500/30 transition-colors">
            <Plane className="w-5 h-5 text-purple-400" />
          </div>
          <div>
            <div className="flex items-center gap-2">
              <h4 className="text-white font-medium">{flight.flightNumber}</h4>
              <span className="px-2 py-0.5 rounded-full text-xs font-medium bg-green-500/20 text-green-400">
                {flight.status}
              </span>
            </div>
            <div className="flex items-center gap-2 mt-1">
              <span className="text-gray-400 text-sm">{flight.origin}</span>
              <ArrowRight className="w-3 h-3 text-gray-600" />
              <span className="text-gray-400 text-sm">{flight.destination}</span>
            </div>
          </div>
        </div>
        <div className="text-right">
          <div className="flex items-center gap-2">
            <Clock className="w-4 h-4 text-blue-400" />
            <span className="text-blue-400 font-medium">{flight.eta}</span>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <div className="relative pt-2">
          <div className="relative h-2 bg-white/5 rounded-full overflow-hidden">
            <motion.div
              className="absolute h-full bg-gradient-to-r from-purple-500 to-pink-500 rounded-full"
              style={{ width: `${flight.progress}%` }}
              initial={{ width: 0 }}
              animate={{ width: `${flight.progress}%` }}
              transition={{ duration: 1, ease: "easeOut" }}
            />
          </div>
          <div className="mt-2 flex justify-between text-xs">
            <span className="text-gray-500">{flight.departureTime}</span>
            <span className="text-gray-500">{flight.arrivalTime}</span>
          </div>
        </div>
      </div>
    </div>
  </motion.div>
);

// Quick Action Button Component
const QuickActionButton = ({ icon: Icon, label }: { icon: LucideIcon; label: string }) => (
  <motion.button
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    className="flex items-center gap-2 px-4 py-2 rounded-xl bg-white/5 border border-white/10 hover:border-purple-500/50 transition-all"
  >
    <Icon className="w-4 h-4 text-purple-400" />
    <span className="text-sm text-gray-300">{label}</span>
  </motion.button>
);

// Alert Component
const AlertItem = ({ type, message }: { type: 'warning' | 'success'; message: string }) => (
  <div className={`flex items-center gap-3 p-3 rounded-lg ${type === 'warning' ? 'bg-yellow-500/10 border-yellow-500/20' : 'bg-green-500/10 border-green-500/20'
    } border`}>
    {type === 'warning' ? (
      <AlertTriangle className="w-5 h-5 text-yellow-500" />
    ) : (
      <CheckCircle2 className="w-5 h-5 text-green-500" />
    )}
    <span className={`text-sm ${type === 'warning' ? 'text-yellow-400' : 'text-green-400'
      }`}>{message}</span>
  </div>
);

// New Fleet Summary Component
const FleetSummary = () => {
  const fleetData = [
    { type: 'Boeing 737-800', count: 12, status: 'Active', utilization: '92%' },
    { type: 'Airbus A320neo', count: 8, status: 'Active', utilization: '88%' },
    { type: 'Boeing 787-9', count: 4, status: 'Active', utilization: '95%' }
  ];

  return (
    <div className="space-y-3">
      {fleetData.map((aircraft, index) => (
        <motion.div
          key={index}
          className="flex items-center justify-between p-3 rounded-lg bg-white/5 border border-white/10"
          whileHover={{ scale: 1.01 }}
        >
          <div className="flex items-center gap-3">
            <div className="p-2 rounded-lg bg-blue-500/10">
              <Plane className="w-4 h-4 text-blue-400" />
            </div>
            <div>
              <h4 className="text-white font-medium">{aircraft.type}</h4>
              <span className="text-gray-400 text-sm">Utilization: {aircraft.utilization}</span>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-right">
              <span className="text-green-400 font-medium">{aircraft.count} Aircraft</span>
              <div className="text-gray-400 text-sm">{aircraft.status}</div>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

// New Weather Info Component
const WeatherInfo = () => {
  const weatherData = [
    { location: 'London (LHR)', temp: '18°C', conditions: 'Cloudy', wind: '15 km/h' },
    { location: 'New York (JFK)', temp: '24°C', conditions: 'Clear', wind: '8 km/h' },
    { location: 'Dubai (DXB)', temp: '36°C', conditions: 'Sunny', wind: '12 km/h' }
  ];

  return (
    <div className="grid grid-cols-3 gap-3">
      {weatherData.map((city, index) => (
        <motion.div
          key={index}
          className="p-3 rounded-lg bg-white/5 border border-white/10"
          whileHover={{ scale: 1.02 }}
        >
          <div className="flex items-center gap-2 mb-2">
            <Cloud className="w-4 h-4 text-blue-400" />
            <h4 className="text-white font-medium">{city.location}</h4>
          </div>
          <div className="flex items-end justify-between">
            <span className="text-2xl font-bold text-white">{city.temp}</span>
            <div className="text-right">
              <div className="text-gray-400 text-sm">{city.conditions}</div>
              <div className="flex items-center gap-1 text-gray-400 text-sm">
                <Wind className="w-3 h-3" />
                {city.wind}
              </div>
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

// New Performance Metrics Component
const PerformanceMetrics = () => {
  const metrics = [
    { label: 'Fuel Efficiency', value: '3.2L/100km', trend: '+5%', icon: Fuel },
    { label: 'Customer Rating', value: '4.8/5', trend: '+0.2', icon: Star },
    { label: 'Safety Score', value: '98/100', trend: '+2', icon: ShieldCheck },
    { label: 'Staff Morale', value: '92%', trend: '+3%', icon: HeartPulse }
  ];

  return (
    <div className="grid grid-cols-4 gap-3">
      {metrics.map((metric, index) => (
        <motion.div
          key={index}
          className="p-3 rounded-lg bg-white/5 border border-white/10"
          whileHover={{ scale: 1.02 }}
        >
          <div className="flex items-center gap-2 mb-2">
            <metric.icon className="w-4 h-4 text-purple-400" />
            <span className="text-gray-400 text-sm">{metric.label}</span>
          </div>
          <div className="flex items-baseline gap-2">
            <span className="text-xl font-bold text-white">{metric.value}</span>
            <span className="text-green-400 text-xs flex items-center gap-1">
              <ArrowUpRight className="w-3 h-3" />
              {metric.trend}
            </span>
          </div>
        </motion.div>
      ))}
    </div>
  );
};


const Dashboard = () => {
  const [activeFlights] = useState<Flight[]>([
    {
      id: '1',
      flightNumber: 'SK1234',
      origin: 'London (LHR)',
      destination: 'New York (JFK)',
      status: 'On Time',
      eta: '2h 15m',
      progress: 65,
      departureTime: '14:30',
      arrivalTime: '17:45'
    },
    {
      id: '2',
      flightNumber: 'SK1235',
      origin: 'Paris (CDG)',
      destination: 'Dubai (DXB)',
      status: 'On Time',
      eta: '4h 30m',
      progress: 35,
      departureTime: '15:00',
      arrivalTime: '19:30'
    }
  ]);

  const stats = [
    {
      icon: Plane,
      title: "Active Flights",
      value: "24",
      trend: "+12% from last week",
      gradient: "from-violet-500 to-indigo-500"
    },
    {
      icon: Building2,
      title: "Hub Cities",
      value: "8",
      gradient: "from-fuchsia-500 to-pink-500"
    },
    {
      icon: Users,
      title: "Total Staff",
      value: "1,247",
      trend: "+5% this month",
      gradient: "from-rose-500 to-red-500"
    },
    {
      icon: DollarSign,
      title: "Revenue Today",
      value: "$842.5K",
      trend: "+8% vs. yesterday",
      gradient: "from-green-500 to-teal-500"
    },
    {
      icon: Star,
      title: "Satisfaction",
      value: "4.8/5",
      trend: "+0.2 this week",
      gradient: "from-blue-500 to-indigo-600"
    },
    {
      icon: Gauge,
      title: "On-Time Rate",
      value: "96%",
      trend: "+2% this month",
      gradient: "from-yellow-500 to-orange-500"
    }
  ];

  return (
    <div className="min-h-screen bg-[#0A0A1E] overflow-hidden">
      <main className=""> {/* Added padding bottom for footer */}
        {/* Quick Actions Bar */}
        <div className="w-full bg-black/40 border-y border-white/10 mb-6">
          <div className="px-6 py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-4">
                <QuickActionButton icon={Wallet} label="Purchase Aircraft" />
                <QuickActionButton icon={CalendarClock} label="Schedule Flight" />
                <QuickActionButton icon={ActivitySquare} label="View Reports" />
                <QuickActionButton icon={Package} label="Cargo Operations" />
                <QuickActionButton icon={RefreshCcw} label="Maintenance" />
              </div>
              <div className="flex items-center gap-6">
                <div className="flex flex-col items-end">
                  <span className="text-sm text-gray-400">Next Maintenance</span>
                  <span className="text-lg font-bold text-white">2d 14h</span>
                </div>
                <div className="w-px h-8 bg-white/10" />
                <div className="flex flex-col items-end">
                  <span className="text-sm text-gray-400">Cash Balance</span>
                  <span className="text-lg font-bold text-white">$24.5M</span>
                </div>
                <div className="w-px h-8 bg-white/10" />
                <div className="flex flex-col items-end">
                  <span className="text-sm text-gray-400">Fuel Reserves</span>
                  <span className="text-lg font-bold text-white">1.2M Gallons</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="px-6">
          {/* Stats Grid */}
          <div className="grid grid-cols-6 gap-4 mb-6">
            {stats.map((stat, index) => (
              <StatCard key={index} {...stat} />
            ))}
          </div>

          {/* Three Column Layout */}
          <div className="grid grid-cols-12 gap-6">
            {/* Left Column - Map and Performance */}
            <div className="col-span-6">
              {/* Map */}
              <div className="rounded-xl overflow-hidden border border-white/10 bg-black/40 backdrop-blur-xl h-[400px] mb-6">
                <div className="relative h-full">
                  <div className="absolute inset-0">
                    <CustomMapbox />
                  </div>
                </div>
              </div>

              {/* Performance Metrics */}
              <div className="rounded-xl border border-white/10 bg-black/40 backdrop-blur-xl p-4 mb-6">
                <h3 className="text-lg font-bold text-white mb-4">Performance Metrics</h3>
                <PerformanceMetrics />
              </div>

              {/* Weather Info */}
              <div className="rounded-xl border border-white/10 bg-black/40 backdrop-blur-xl p-4">
                <h3 className="text-lg font-bold text-white mb-4">Hub Weather Conditions</h3>
                <WeatherInfo />
              </div>
            </div>

            {/* Middle Column - Fleet and Alerts */}
            <div className="col-span-3 space-y-6">
              {/* Fleet Summary */}
              <div className="rounded-xl border border-white/10 bg-black/40 backdrop-blur-xl p-4">
                <h3 className="text-lg font-bold text-white mb-4">Fleet Summary</h3>
                <FleetSummary />
              </div>

              {/* Alerts Section */}
              <div className="rounded-xl border border-white/10 bg-black/40 backdrop-blur-xl p-4">
                <h3 className="text-lg font-bold text-white mb-4">Recent Alerts</h3>
                <div className="space-y-3">
                  <AlertItem
                    type="warning"
                    message="Maintenance required for SK1234 in 48 hours"
                  />
                  <AlertItem
                    type="warning"
                    message="Weather alert: Strong winds at JFK"
                  />
                  <AlertItem
                    type="success"
                    message="All flights operating on schedule today"
                  />
                  <AlertItem
                    type="success"
                    message="Fuel efficiency targets met for this month"
                  />
                </div>
              </div>
            </div>

            {/* Right Column - Active Flights */}
            <div className="col-span-3">
              <div className="rounded-xl border border-white/10 bg-black/40 backdrop-blur-xl p-4">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center gap-2">
                    <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse" />
                    <h3 className="text-lg font-bold text-white">Live Flights</h3>
                  </div>
                  <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    className="p-2 rounded-lg hover:bg-white/5 transition-colors"
                  >
                    <MoreHorizontal className="w-5 h-5 text-gray-400" />
                  </motion.button>
                </div>

                <div className="space-y-3 max-h-[800px] overflow-y-auto pr-2 custom-scrollbar">
                  {activeFlights.map(flight => (
                    <ActiveFlight key={flight.id} flight={flight} />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Dashboard;