import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Mail, Lock, User, Eye, EyeOff, AlertCircle, ArrowLeft, Check, Loader2, Chrome as Google } from 'lucide-react';
import { Header, Footer } from '@/components/ui/FootHead';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { useNavigate } from 'react-router-dom';
import { auth } from '@/lib/firebase';
import { authService } from '@/services/auth.service';
import { onAuthStateChanged, AuthError } from 'firebase/auth';

interface FormFields {
  email: string;
  password: string;
  username?: string;
  confirmPassword?: string;
}

interface FormErrors {
  email?: string;
  password?: string;
  username?: string;
  confirmPassword?: string;
}

const CustomCheckbox = ({ checked, onChange, label }: { checked: boolean; onChange: () => void; label: string }) => (
  <div
    onClick={onChange}
    className="flex items-center gap-2 cursor-pointer group"
  >
    <div className={`
      w-5 h-5 rounded-md flex items-center justify-center
      transition-all duration-200
      ${checked
        ? 'bg-gradient-to-r from-purple-500 to-pink-500 border-transparent'
        : 'border-2 border-white/20 bg-white/5'}
      group-hover:border-purple-500/50
    `}>
      <AnimatePresence>
        {checked && (
          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            exit={{ scale: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Check className="h-3 w-3 text-white" />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
    <span className="text-sm text-gray-400 group-hover:text-gray-300 transition-colors">
      {label}
    </span>
  </div>
);

export const AuthPage = () => {
  const [isSignIn, setIsSignIn] = useState(true);
  const queryParams = new URLSearchParams(window.location.search);
  const mode = queryParams.get('mode');
  useEffect(() => {
    if (mode === 'signin') {
      setIsSignIn(true);
    } else if (mode === 'signup') {
      setIsSignIn(false);
    }
  }, [mode]);
  const [formData, setFormData] = useState<FormFields>({
    email: '',
    password: '',
    username: '',
    confirmPassword: '',
  });
  const [errors, setErrors] = useState<FormErrors>({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [isResetting, setIsResetting] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const navigate = useNavigate();

  const saveUserLocally = (user: any) => {
    localStorage.setItem('user', JSON.stringify(user));
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await authService.getUserData(user.uid);
          if (userDoc) {
            saveUserLocally(user);
            navigate('/game');
          }
        } catch (error) {
          await auth.signOut();
        }
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const validateForm = () => {
    const newErrors: FormErrors = {};
    if (!isSignIn && !formData.username) {
      newErrors.username = 'Username is required';
    }
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required';
    } else if (!isSignIn && formData.password.length < 8) {
      newErrors.password = 'Password must be at least 8 characters';
    }
    if (!isSignIn && formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    if (!isSignIn && !termsAccepted) {
      newErrors.username = 'Please accept the terms and conditions';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      if (isSignIn) {
        await authService.signIn(formData.email, formData.password);
      } else {
        await authService.signUp(formData.email, formData.password, formData.username || '');
      }
    } catch (error: any) {
      const authError = error as AuthError;
      // Handle username-specific errors
      if (authError.code === 'auth/username-already-in-use' ||
        authError.code === 'auth/invalid-username-format') {
        setErrors(prev => ({
          ...prev,
          username: authService.getReadableErrorMessage(authError)
        }));
      } else {
        setErrors(prev => ({
          ...prev,
          password: authService.getReadableErrorMessage(authError)
        }));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!resetEmail || !/\S+@\S+\.\S+/.test(resetEmail)) {
      return;
    }

    setIsResetting(true);
    try {
      await authService.resetPassword(resetEmail);
      setResetSuccess(true);
      setTimeout(() => {
        setShowForgotPassword(false);
        setResetSuccess(false);
        setResetEmail('');
      }, 2000);
    } catch (error) {
      const authError = error as AuthError;
      setErrors({
        ...errors,
        email: authService.getReadableErrorMessage(authError)
      });
    } finally {
      setIsResetting(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setIsLoading(true);
      await authService.signInWithGoogle();
      // Navigation will be handled by the auth state observer
    } catch (error: any) {
      const authError = error as AuthError;
      setErrors(prev => ({
        ...prev,
        password: authService.getReadableErrorMessage(authError)
      }));
    } finally {
      setIsLoading(false);
    }
  };

  const toggleAuthMode = () => {
    setErrors({});
    setShowPassword(false);
    setShowConfirmPassword(false);
    setIsSignIn(!isSignIn);
  };

  return (
    <div className="min-h-screen bg-[#0A0A1E] overflow-hidden">
      {/* Enhanced Animated Background */}
      <div className="fixed inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(to_right,#ffffff10_1px,transparent_1px),linear-gradient(to_bottom,#ffffff10_1px,transparent_1px)] bg-[size:14px_24px] opacity-20" />
        <motion.div
          className="absolute inset-0 bg-gradient-to-br from-purple-900/30 via-transparent to-blue-900/30"
          animate={{
            background: [
              "linear-gradient(to bottom right, rgba(88,28,135,0.3), transparent, rgba(29,78,216,0.3))",
              "linear-gradient(to bottom right, rgba(29,78,216,0.3), transparent, rgba(88,28,135,0.3))"
            ]
          }}
          transition={{ duration: 10, repeat: Infinity, repeatType: "reverse" }}
        />
      </div>

      <Header />

      <div className="relative min-h-screen flex items-center justify-center py-32">
        <motion.div
          className="w-full max-w-md px-4"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <motion.div
            initial={false}
            animate={{ height: isSignIn ? 'auto' : 'auto' }}
            className="relative z-10 bg-white/5 backdrop-blur-xl rounded-2xl border border-white/10 p-8 overflow-hidden"
          >
            <AnimatePresence mode="wait">
              <motion.div
                key={isSignIn ? 'signin' : 'signup'}
                initial={{ x: isSignIn ? -400 : 400, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: isSignIn ? 400 : -400, opacity: 0 }}
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
              >
                <div className="text-center mb-8">
                  <motion.h2
                    className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-400 mb-2"
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.2 }}
                  >
                    {isSignIn ? 'Welcome Back' : 'Create Account'}
                  </motion.h2>
                  <motion.p
                    className="text-gray-400"
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ delay: 0.3 }}
                  >
                    {isSignIn ? 'Sign in to continue to SkyManager' : 'Join SkyManager today'}
                  </motion.p>
                </div>

                <form onSubmit={handleSubmit} className="space-y-6">
                  <AnimatePresence>
                    {!isSignIn && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3 }}
                      >
                        <div className="relative">
                          <User className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                          <input
                            type="text"
                            value={formData.username}
                            onChange={(e) => setFormData({ ...formData, username: e.target.value })}
                            className="w-full pl-10 pr-4 py-3 bg-white/5 border border-white/10 rounded-xl text-white placeholder:text-gray-400 focus:outline-none focus:border-purple-500 transition-all"
                            placeholder="Username"
                          />
                          {errors.username && (
                            <motion.p
                              initial={{ opacity: 0, y: -10 }}
                              animate={{ opacity: 1, y: 0 }}
                              className="text-red-400 text-sm mt-1 flex items-center gap-1"
                            >
                              <AlertCircle className="h-4 w-4" />
                              {errors.username}
                            </motion.p>
                          )}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>

                  {/* Email Input */}
                  <div className="relative group">
                    <Mail className="absolute left-3 top-3 h-5 w-5 text-gray-400 group-hover:text-purple-400 transition-colors" />
                    <input
                      type="email"
                      value={formData.email}
                      onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                      className="w-full pl-10 pr-4 py-3 bg-white/5 border border-white/10 rounded-xl text-white placeholder:text-gray-400 focus:outline-none focus:border-purple-500 group-hover:border-purple-500/50 transition-all"
                      placeholder="Email address"
                    />
                    {errors.email && (
                      <motion.p
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-red-400 text-sm mt-1 flex items-center gap-1"
                      >
                        <AlertCircle className="h-4 w-4" />
                        {errors.email}
                      </motion.p>
                    )}
                  </div>

                  {/* Password Input */}
                  <div className="relative group">
                    <Lock className="absolute left-3 top-3 h-5 w-5 text-gray-400 group-hover:text-purple-400 transition-colors" />
                    <input
                      type={showPassword ? 'text' : 'password'}
                      value={formData.password}
                      onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                      className="w-full pl-10 pr-12 py-3 bg-white/5 border border-white/10 rounded-xl text-white placeholder:text-gray-400 focus:outline-none focus:border-purple-500 group-hover:border-purple-500/50 transition-all"
                      placeholder="Password"
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-3 text-gray-400 hover:text-white transition-colors"
                    >
                      {showPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                    </button>
                    {errors.password && (
                      <motion.p
                        initial={{ opacity: 0, y: -10 }}
                        animate={{ opacity: 1, y: 0 }}
                        className="text-red-400 text-sm mt-1 flex items-center gap-1"
                      >
                        <AlertCircle className="h-4 w-4" />
                        {errors.password}
                      </motion.p>
                    )}
                  </div>

                  <AnimatePresence>
                    {!isSignIn && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        className="relative group"
                      >
                        <Lock className="absolute left-3 top-3 h-5 w-5 text-gray-400 group-hover:text-purple-400 transition-colors" />
                        <input
                          type={showConfirmPassword ? 'text' : 'password'}
                          value={formData.confirmPassword}
                          onChange={(e) => setFormData({ ...formData, confirmPassword: e.target.value })}
                          className="w-full pl-10 pr-12 py-3 bg-white/5 border border-white/10 rounded-xl text-white placeholder:text-gray-400 focus:outline-none focus:border-purple-500 group-hover:border-purple-500/50 transition-all"
                          placeholder="Confirm password"
                        />
                        <button
                          type="button"
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          className="absolute right-3 top-3 text-gray-400 hover:text-white transition-colors"
                        >
                          {showConfirmPassword ? <EyeOff className="h-5 w-5" /> : <Eye className="h-5 w-5" />}
                        </button>
                      </motion.div>
                    )}
                  </AnimatePresence>

                  {isSignIn ? (
                    <div className="flex items-center justify-between text-sm">
                      <CustomCheckbox
                        checked={rememberMe}
                        onChange={() => setRememberMe(!rememberMe)}
                        label="Remember me"
                      />
                      <button
                        type="button"
                        onClick={() => setShowForgotPassword(true)}
                        className="text-purple-400 hover:text-purple-300 transition-colors"
                      >
                        Forgot password?
                      </button>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <CustomCheckbox
                        checked={termsAccepted}
                        onChange={() => setTermsAccepted(!termsAccepted)}
                        label="I agree to the Terms of Service and Privacy Policy"
                      />
                    </div>
                  )}

                  <button
                    type="submit"
                    disabled={isLoading}
                    className="w-full py-3 rounded-xl bg-gradient-to-r from-purple-500 to-pink-500 text-white font-semibold relative overflow-hidden group disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-1000" />
                    <span className="relative flex items-center justify-center gap-2">
                      {isLoading ? (
                        <motion.div
                          className="h-5 w-5 border-2 border-white border-t-transparent rounded-full mx-auto"
                          animate={{ rotate: 360 }}
                          transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
                        />
                      ) : (
                        <>
                          {isSignIn ? 'Sign In' : 'Create Account'}
                          <motion.div
                            animate={{ x: [0, 5, 0] }}
                            transition={{ duration: 1, repeat: Infinity }}
                          >
                            <ArrowLeft className="h-4 w-4 transform rotate-180" />
                          </motion.div>
                        </>
                      )}
                    </span>
                  </button>

                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-white/10" />
                    </div>
                    <div className="relative flex justify-center text-sm">
                      <span className="px-2 bg-[#0A0A1E]/50 text-gray-400 backdrop-blur-xl">
                        Or continue with
                      </span>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4">
                    <motion.button
                      type="button"
                      onClick={handleGoogleSignIn}
                      disabled={isLoading}
                      className="relative w-full inline-flex items-center justify-center gap-3 p-3 
                          border border-white/10 rounded-xl bg-white/5 hover:bg-white/10 
                          transition-all group overflow-hidden"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <div className="absolute inset-0 bg-gradient-to-r from-white/5 to-transparent 
                          transform -skew-x-12 -translate-x-full group-hover:translate-x-full 
                          transition-transform duration-1000"
                      />
                      {isLoading ? (
                        <Loader2 className="h-5 w-5 animate-spin text-gray-400" />
                      ) : (
                        <>
                          <div className="w-5 h-5 bg-white rounded-full flex items-center justify-center">
                            <Google className="h-3 w-3 text-gray-900" />
                          </div>
                          <span className="text-gray-300 font-medium">
                            Continue with Google
                          </span>
                        </>
                      )}
                    </motion.button>
                  </div>
                </form>

                <p className="mt-6 text-center text-gray-400">
                  {isSignIn ? "Don't have an account? " : "Already have an account? "}
                  <button
                    onClick={toggleAuthMode}
                    className="text-purple-400 hover:text-purple-300 transition-colors inline-flex items-center gap-1 group"
                  >
                    {isSignIn ? 'Sign up' : 'Sign in'}
                    <ArrowLeft className="h-4 w-4 transform rotate-180 group-hover:translate-x-1 transition-transform" />
                  </button>
                </p>
              </motion.div>
            </AnimatePresence>
          </motion.div>
        </motion.div>
        {/* Forgot Password Dialog */}
        <Dialog isOpen={showForgotPassword} onClose={() => setShowForgotPassword(false)}>
          <DialogContent className="bg-[#0A0A1E]/95 backdrop-blur-xl border border-white/10 p-6 overflow-hidden text-white rounded-2xl shadow-2xl shadow-purple-500/10">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4 }}
            >
              <DialogHeader className="space-y-4">
                <DialogTitle className="text-3xl font-bold text-center">
                  <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400 animate-gradient">
                    Reset Password
                  </span>
                </DialogTitle>
                <DialogDescription className="text-gray-400 text-center leading-relaxed">
                  Enter your email address and we'll send you instructions
                  <br />to reset your password.
                </DialogDescription>
              </DialogHeader>

              <form onSubmit={handleResetPassword} className="space-y-6 mt-6">
                <motion.div
                  className="relative group"
                  whileTap={{ scale: 0.995 }}
                >
                  <Mail className="absolute left-3 top-3 h-5 w-5 text-gray-400 group-hover:text-purple-400 transition-colors" />
                  <input
                    type="email"
                    value={resetEmail}
                    onChange={(e) => setResetEmail(e.target.value)}
                    className="w-full pl-10 pr-4 py-3 bg-white/5 border border-white/10 rounded-xl text-white placeholder:text-gray-400 
              focus:outline-none focus:border-purple-500 focus:ring-2 focus:ring-purple-500/20 
              group-hover:border-purple-500/50 transition-all duration-300"
                    placeholder="Enter your email"
                    disabled={isResetting || resetSuccess}
                  />
                  <div className="absolute inset-0 rounded-xl bg-gradient-to-r from-purple-500/20 to-pink-500/20 opacity-0 group-hover:opacity-100 transition-opacity duration-300 -z-10 blur-xl" />
                </motion.div>

                <motion.button
                  type="submit"
                  disabled={isResetting || resetSuccess || !resetEmail}
                  className="w-full py-3 rounded-xl bg-gradient-to-r from-purple-500 via-pink-500 to-purple-500 bg-[length:200%_200%] 
            text-white font-semibold relative overflow-hidden group disabled:opacity-50 disabled:cursor-not-allowed"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  animate={{ backgroundPosition: ['0% 0%', '100% 100%'] }}
                  transition={{ backgroundPosition: { duration: 3, repeat: Infinity, ease: 'linear' } }}
                >
                  <div className="absolute inset-0 bg-white/20 transform -skew-x-12 -translate-x-full group-hover:translate-x-full transition-transform duration-1000" />
                  <span className="relative flex items-center justify-center gap-2">
                    {isResetting ? (
                      <Loader2 className="h-5 w-5 animate-spin" />
                    ) : resetSuccess ? (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        className="flex items-center gap-2"
                      >
                        <Check className="h-5 w-5" />
                        Email Sent!
                      </motion.div>
                    ) : (
                      'Reset Password'
                    )}
                  </span>
                </motion.button>
              </form>
            </motion.div>
          </DialogContent>
        </Dialog>
      </div>
      <Footer />
    </div>
  );
};
export default AuthPage;