import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Bell, Settings, ChevronDown, Globe,
  HomeIcon, PlaneIcon, MapIcon,
  DollarSignIcon, Building2Icon, TrendingUpIcon,
  ShieldIcon, MessageSquareIcon, SettingsIcon,
  DollarSign,
  Plane,
  Building2,
  Users,
  Star,
  Gauge,
  Clock,
  TrendingUp,
  ArrowLeft
} from 'lucide-react';
import { UserData } from '@/types';
import { authService } from '@/services/auth.service';

const navItems = [
  { id: 'dashboard', icon: HomeIcon, label: 'Dashboard' },
  { id: 'fleet', icon: PlaneIcon, label: 'Fleet' },
  { id: 'routes', icon: MapIcon, label: 'Routes' },
  { id: 'staff', icon: Users, label: 'Staff' },
  { id: 'finance', icon: DollarSignIcon, label: 'Finance' },
  { id: 'airports', icon: Building2Icon, label: 'Airports' },
  { id: 'market', icon: TrendingUpIcon, label: 'Market' },
  { id: 'safety', icon: ShieldIcon, label: 'Safety' },
  { id: 'support', icon: MessageSquareIcon, label: 'Support' },
  { id: 'settings', icon: SettingsIcon, label: 'Settings' }
];

interface GameHeaderProps {
  onTabChange: (page: string) => void;
  activeTab: string;
}
export const GameHeader: React.FC<GameHeaderProps> = ({ onTabChange, activeTab }) => {
  const navigate = useNavigate();
  const [notifications] = useState([]);
  const [ActiveTab, setActiveTab] = useState(activeTab);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [userData, setUserData] = useState<UserData | null>(null);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        const storedUser = localStorage.getItem('user');
        if (!storedUser) {
          navigate('/auth');
          return;
        }

        const user = JSON.parse(storedUser);
        const data = await authService.getUserData(user.uid);
        if (data) {
          setUserData(data);
        }
        console.log('User data loaded:', data);
      } catch (error) {
        console.error('Error loading user data:', error);
        localStorage.removeItem('user');
        window.location.reload();
        navigate('/auth');
      }
    };

    loadUserData();
  }, [navigate]);

  const handleTabClick = (tabId: string) => {
    setActiveTab(tabId);
    onTabChange(tabId);
  };

  return (
    <header className="fixed top-0 left-0 right-0 z-50">
      <div className="bg-[#0A0A1E]/90 backdrop-blur-xl border-b border-white/10">
        {/* Top Bar */}
        <div className="h-12">
          <div className="flex items-center justify-between h-full px-4">
            {/* Left Section */}
            <div className="flex items-center gap-3">
              <motion.button
                onClick={() => navigate('/')}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="group p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-all"
              >
                <ArrowLeft className="w-4 h-4 text-gray-400" />
              </motion.button>

              <motion.div
                className="text-lg font-bold"
                animate={{
                  backgroundPosition: ['0% 50%', '100% 50%'],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  ease: 'linear',
                }}
                style={{
                  backgroundSize: '200% auto',
                  backgroundImage: 'linear-gradient(90deg, #A855F7, #EC4899, #EF4444, #A855F7)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                SkyManager
              </motion.div>

              <div className="relative">
                <motion.div
                  onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  className="flex items-center gap-2 px-3 py-1.5 rounded-lg bg-white/5 border border-white/10 hover:border-purple-500/50 cursor-pointer group transition-all"
                  whileHover={{ scale: 1.02 }}
                >
                  <Globe className="w-4 h-4 text-purple-400" />
                  <span className="text-gray-300 text-xs">Scorcher Airlines</span>
                  <ChevronDown
                    className={`w-3 h-3 text-gray-500 group-hover:text-gray-400 transition-transform ${isDropdownOpen ? "rotate-180" : ""
                      }`}
                  />
                </motion.div>

                <AnimatePresence>
                  {isDropdownOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      className="absolute mt-2 left-0 w-56 bg-[#0A0A1E] border border-white/10 rounded-lg shadow-xl z-50"
                    >
                      <div className="p-2">
                        <div className="mb-2 space-y-1">
                          {["Scorcher Airlines", "Sky Express"].map((airline, index) => (
                            <div
                              key={index}
                              className="flex items-center justify-between px-3 py-2 rounded-lg hover:bg-white/5 transition-colors"
                            >
                              <button className="text-left text-sm text-gray-300">
                                {airline}
                              </button>
                            </div>
                          ))}
                        </div>
                        <div className="pt-2 border-t border-white/10">
                          <button
                            onClick={() => {
                              if (!userData) {
                                navigate('/auth');
                                return;
                              }
                            }}
                            className="w-full px-3 py-2 text-sm text-purple-400 hover:bg-white/5 rounded-lg transition-colors flex items-center gap-2"
                          >
                            <span className="text">+</span>
                            Create new airline
                          </button>
                        </div>
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>

            </div>

            {/* Right Section */}
            <div className="flex items-center gap-2">
              <motion.button
                whileHover={{ scale: 1.05 }}
                className="relative p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-all"
              >
                <Bell className="w-5 h-5 text-gray-400" />
                <AnimatePresence>
                  {notifications.length > 0 && (
                    <motion.div
                      initial={{ scale: 0 }}
                      animate={{ scale: 1 }}
                      exit={{ scale: 0 }}
                      className="absolute -top-1 -right-1 w-5 h-5 bg-gradient-to-r from-purple-500 to-pink-500 rounded-full flex items-center justify-center"
                    >
                      <span className="text-[10px] font-bold text-white">
                        {notifications.length}
                      </span>
                    </motion.div>
                  )}
                </AnimatePresence>
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.05 }}
                className="p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-all"
              >
                <Settings className="w-5 h-5 text-gray-400" />
              </motion.button>

              <motion.button
                whileHover={{ scale: 1.05 }}
                onClick={() => navigate('/account')}
                className="p-2 rounded-lg bg-white/5 hover:bg-white/10 transition-all"
              >
                <span className="text-gray-300 text-xs">
                  {userData?.username || 'Sign In'}
                </span>
              </motion.button>
            </div>
          </div>
        </div>

        {/* Navigation Tabs */}
        <div className="h-12 px-4">
          <div className="flex items-center gap-1 h-full">
            {navItems.map((item) => (
              <motion.button
                key={item.id}
                onClick={() => handleTabClick(item.id)}
                className={`
                    relative px-4 h-9 rounded-lg flex items-center gap-2
                    text-sm font-medium transition-all
                    ${ActiveTab === item.id
                    ? 'text-white'
                    : 'text-gray-400 hover:text-white'}
                    `}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                {ActiveTab === item.id && (
                  <motion.div
                    layoutId="active-tab"
                    className="absolute inset-0 bg-white/5 border border-white/10 rounded-lg"
                    transition={{ type: "spring", bounce: 0.2, duration: 0.6 }}
                  />
                )}
                <span className="relative flex items-center gap-2">
                  <item.icon className="w-4 h-4" />
                  {item.label}
                </span>
              </motion.button>
            ))}
          </div>
        </div>
      </div>

      {/* Animated Page Indicator */}
      <div className="h-[2px] w-full bg-gradient-to-r from-purple-500/20 via-pink-500/20 to-red-500/20">
        <motion.div
          className="h-full bg-gradient-to-r from-purple-500 via-pink-500 to-red-500"
          initial={{ width: "0%" }}
          animate={{ width: "100%" }}
          transition={{ duration: 0.8, ease: "easeOut" }}
        />
      </div>
    </header>
  );
};

export const GameFooter = () => {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => setCurrentTime(new Date()), 1000);
    return () => clearInterval(timer);
  }, []);

  const stats = [
    {
      icon: DollarSign,
      label: 'Revenue',
      value: '$24.5M',
      trend: '+8.2%',
      gradient: 'from-green-400 to-emerald-500'
    },
    {
      icon: Plane,
      label: 'Fleet',
      value: '24',
      trend: '+3',
      gradient: 'from-blue-400 to-indigo-500'
    },
    {
      icon: Building2,
      label: 'Hubs',
      value: '8',
      trend: '+1',
      gradient: 'from-purple-400 to-pink-500'
    },
    {
      icon: Users,
      label: 'Staff',
      value: '1,247',
      trend: '+15',
      gradient: 'from-pink-400 to-rose-500'
    },
    {
      icon: Star,
      label: 'Rating',
      value: '4.8',
      trend: '+0.2',
      gradient: 'from-amber-400 to-yellow-500'
    },
    {
      icon: Gauge,
      label: 'On-Time',
      value: '96%',
      trend: '+2.4%',
      gradient: 'from-violet-400 to-purple-500'
    }
  ];

  return (
    <footer className="fixed bottom-0 left-0 right-0 z-40">
      <div className="h-[1px] w-full bg-gradient-to-r from-purple-400/20 via-pink-400/20 to-red-400/20" />
      <div className="bg-[#0A0A1E]/90 backdrop-blur-xl">
        <div className="h-14 px-4">
          <div className="flex items-center gap-4 h-full">
            {/* System Status */}
            <div className="flex items-center gap-2 px-3 py-1.5 rounded-lg bg-white/5 border border-white/10 h-9">
              <motion.div
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ duration: 2, repeat: Infinity }}
                className="w-1.5 h-1.5 rounded-full bg-emerald-400"
              />
              <Clock className="w-3.5 h-3.5 text-purple-400" />
              <span className="text-gray-300 text-xs">
                {currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
              </span>
            </div>

            {/* Stats */}
            <div className="flex-1 grid grid-cols-6 gap-2">
              {stats.map((stat, index) => (
                <motion.div
                  key={index}
                  className="group relative h-9"
                  whileHover={{ scale: 1.02 }}
                >
                  <div className={`absolute inset-0 rounded-lg bg-gradient-to-br ${stat.gradient} opacity-0 group-hover:opacity-10 transition-all duration-300 blur-md`} />
                  <div className="relative flex items-center h-full px-2 rounded-lg bg-white/5 border border-white/10 hover:border-white/20">
                    <div className="flex items-center gap-2">
                      <stat.icon className={`w-3.5 h-3.5 text-gray-300 group-hover:text-white transition-colors`} />
                      <div className="flex flex-col -space-y-0.5">
                        <div className="flex items-center gap-1">
                          <span className="text-white text-xs font-medium">{stat.value}</span>
                          <span className="text-emerald-400 text-[9px] flex items-center">
                            <TrendingUp className="w-2 h-2 mr-0.5" />
                            {stat.trend}
                          </span>
                        </div>
                        <span className="text-gray-500 text-[9px]">{stat.label}</span>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

            {/* Version */}
            <div className="px-3 py-1.5 rounded-lg bg-white/5 border border-white/10">
              <span className="text-gray-500 text-xs">v1.0.0</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};